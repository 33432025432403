import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { quantum } from 'ldrs';

quantum.register();

const PageLoadingSpinner: React.FC = () => {
    return (
        <Flex
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
            bg="none"
        >
            <Box>
                <l-quantum size="45" speed="1.00" color="black"></l-quantum>
            </Box>
        </Flex>
    );
};

export default PageLoadingSpinner;