import React, {useEffect, useState} from 'react';
import {
    Flex,
    Text,
    VStack,
    useColorModeValue,
    useBreakpointValue,
    Stack,
} from '@chakra-ui/react';
import NewProjectComponent from "./newProject/NewProjectComponent";
import SearchComponent from "../common/SearchComponent";
import {useTranslation} from "react-i18next";
import ProjectPreviewComponent from "./ProjectPreviewComponent";
import ProjectModalComponent from "./ProjectModalComponent";


interface ProjectsProps {
    selectedTag: string;
    projects: any;
    callback: () => void;
}

function ProjectsComponent(props: ProjectsProps) {
    const [animate, setAnimate] = useState(false);
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const [selectedProject, setSelectedProject] = useState(null);
    const {t} = useTranslation();
    const [widthSize, setWidthSize] = useState(window.innerWidth);
    const handleCloseModal = () => {
        setSelectedProject(null);
    };

    useEffect(() => {
        const handleResize = () => {
            setWidthSize(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [searchTerm, setSearchTerm] = useState('');

    const handleProjectClick = (project: any) => {
        setSelectedProject(project);
    };

    const filteredprojects = props.projects.filter((project: any) => {
        const searchRegex = new RegExp(searchTerm, 'i');
        return (
            searchRegex.test(project.reference) ||
            searchRegex.test(project.address));
    });


    return (
        <Flex direction="column" bg={useColorModeValue('gray.100', 'gray.700')} rounded="md" boxShadow="md"
              py={isSmallScreen ? 3 : 10}
              width={widthSize < 1111 ? "100%" : "50%"}
              marginTop={isSmallScreen ? 3 : 5}
              marginX={'auto'}
              height={"auto"} minHeight={window.innerHeight - 174} maxHeight={window.innerHeight - 174}
        >
            <Stack spacing={3} marginX='3%' marginBottom='3%'>
                <NewProjectComponent callback={props.callback}/>
                <SearchComponent value={searchTerm} onChange={(e) => setSearchTerm(e)}/>
            </Stack>
            <VStack spacing="2" align="stretch" paddingX={5} maxHeight={`calc(100% - 70px)`} overflowY={"auto"}
                    className={"custom-scrollbar"}>
                {props.projects
                    .filter((project: any) => {
                        const searchRegex = new RegExp(searchTerm, 'i');
                        return (
                            searchRegex.test(project.reference) || searchRegex.test(project.address)
                        );
                    })
                    .map((project: any) => (
                        <ProjectPreviewComponent key={project._id} project={project} handleProjectClick={() => handleProjectClick(project)} callback={props.callback}/>
                    ))}
            </VStack>

            {filteredprojects.length === 0 && (
                <Text mt="4" color="gray.500" fontSize="lg" fontWeight="bold" textAlign="center" fontFamily="serif">
                    Aucun résultat trouvé !
                </Text>
            )}
            <ProjectModalComponent selectedProject={selectedProject} handleCloseModal={handleCloseModal} callback={props.callback}/>
        </Flex>
    );
}

export default ProjectsComponent;
