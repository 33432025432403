import React, {useState, useEffect} from "react";
import {
    FormControl,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useColorModeValue,
    useToast,
    Flex,
    Heading,
    IconButton,
    Divider,
    useBreakpointValue,
} from "@chakra-ui/react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import InputComponent from "../common/InputComponent";
import ButtonComponent from "../common/ButtonComponent";
import axios from "axios";
import {getAuthorizedHeader} from "../../common/auth";
import makeAnimated from "react-select/animated";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons";
import NewClientComponent, {IClientFormData} from "../project/newProject/NewClientComponent";
import {useTranslation} from "react-i18next";

interface NewAppointmentModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
}

interface OptionType {
    value: string;
    label: string;
    color?: string;
}

interface User {
    _id: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
}

interface Client {
    _id: string;
    name: string;
    email: string;
    phone: string;
}

const NewAppointmentComponent: React.FC<NewAppointmentModalProps> = ({
                                                                         isOpen,
                                                                         onClose,
                                                                         onSave,
                                                                     }) => {
    const [type, setType] = useState<OptionType | null>(null);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [date, setDate] = useState("");
    const [timeStart, setTimeStart] = useState("");
    const [timeEnd, setTimeEnd] = useState("");
    const [note, setNote] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [participants, setParticipants] = useState<OptionType[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const [newClient, setNewClient] = useState(false);
    const {t} = useTranslation();
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [clientFormData, setClientFormData] = useState({
        email: '',
        name: '',
        phone: '',
    } as IClientFormData);
    const toast = useToast();

    const fetchData = async () => {
        try {
            const [usersResponse, clientsResponse, currentUserResponse] = await Promise.all([
                axios.get("/api/users", { headers: getAuthorizedHeader() }),
                axios.get("/api/clients", { headers: getAuthorizedHeader() }),
                axios.get("/api/me", { headers: getAuthorizedHeader() }),
            ]);

            setUsers(usersResponse.data);
            setClients(clientsResponse.data);
            const user = currentUserResponse.data;
            setCurrentUser(user);

            setParticipants([
                {
                    value: user._id,
                    label: `${user.firstname} ${user.lastname}`,
                },
            ]);
        } catch (error) {
            console.error("Error fetching data", error);
            toast({
                title: "Error",
                description: "Failed to fetch data.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSave = async () => {

        const newAppointment = {
            name: name,
            address: address,
            type: type.value,
            note: note,
            participants: participants.map((p) => p.value),
            date: date,
            email: email,
            phone: phone,
            timeStart: timeStart,
            timeEnd: timeEnd,
        };
        try {
            const response = await axios.post("/api/appointment", newAppointment, {
                headers: getAuthorizedHeader(),
            });
            onSave();
            onClose();
            toast({
                title: "Appointment created.",
                description: "Your appointment has been successfully created.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top",
            });
        } catch (error) {
            console.error("Error saving appointment", error);
            toast({
                title: "Error",
                description: "There was an error creating the appointment.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
            });
        }
    };

    const loadOptions = (
        inputValue: string,
        callback: (options: OptionType[]) => void
    ) => {
        if (!inputValue) {
            callback([]);
            return;
        }
        const filteredUsers = users.filter((user) =>
            `${user.firstname} ${user.lastname}`
                .toLowerCase()
                .includes(inputValue.toLowerCase())
        );
        const filteredClients = clients.filter((client) =>
            client.name.toLowerCase().includes(inputValue.toLowerCase())
        );

        const userOptions: OptionType[] = filteredUsers.map((user) => ({
            value: user._id,
            label: `${user.firstname} ${user.lastname}`,
        }));
        const clientOptions: OptionType[] = filteredClients.map((client) => ({
            value: client._id,
            label: client.name,
        }));

        callback([...userOptions, ...clientOptions]);
    };

    const handleParticipantsChange = (selectedOptions: OptionType[]) => {
        setParticipants(selectedOptions);
    };

    const options: OptionType[] = [
        {value: "project", label: "Project", color: "#3182CE"},
        {value: "banque", label: "Banque", color: "#D69E2E"},
        {value: "reunion", label: "Réunion de groupe", color: "#38A169"},
        {value: "rendez-vous", label: "Rendez-vous", color: "#9F7AEA"},
        {value: "autre", label: "Autre", color: "#FF69B4"},
    ];

    const handleClientFormSubmit = (data: IClientFormData) => {
        setClientFormData(data);
        const {email, name, phone} = data;

        axios.post('/api/client', {name, phone, email}, {headers: getAuthorizedHeader()})
            .then(() => {
                toast({
                    title: 'Client created.',
                    description: "The new client has been successfully created.",
                    status: 'success',
                    duration: 666,
                    isClosable: true,
                    position: "top",
                });
                fetchData()
            })
            .catch(reason => {
                toast({
                    title: 'Error creating client.',
                    description: `Unable to create client: ${reason.message}`,
                    status: 'error',
                    duration: 666,
                    isClosable: true,
                    position: "top",
                });
            });
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setNewClient(false);
    };

    const handleOpenModal = () => {
        setNewClient(true);
    };

    const borderColor = useColorModeValue('#1C2942', '#BFBFB8');
    const hoverBgColor = useColorModeValue('#A3A3A3', '#262F40');
    const labelColor = useColorModeValue('gray.600', '#A3A3A3');
    const placeholderColor = useColorModeValue('black', '#A3A3A3');

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{t("NewAppointment")}</ModalHeader>
                <ModalCloseButton
                    position="absolute"
                    top="1rem"
                    right="1rem"
                    bg="transparent"
                    _hover={{bg: "transparent"}}
                    _active={{bg: "transparent"}}
                    sx={{
                        "& svg": {
                            transition: "all 0.2s ease-in-out",
                        },
                        "& svg path": {
                            stroke: "white",
                            transition: "stroke 0.3s ease-in-out",
                        },
                        "&:hover svg path": {
                            stroke: "red",
                            transition: "stroke 0.5s ease-in-out 0s",
                        },
                    }}
                />
                <ModalBody>
                    <ButtonComponent text={t("NewClient")} callback={handleOpenModal}/>
                    <Modal isOpen={newClient}
                           onClose={handleCloseModal}
                           size={isSmallScreen ? "auto" : "xl"}
                    >
                        <ModalOverlay bg='none'
                                      backdropFilter='auto'
                                      backdropBlur='2px'/>
                        <ModalContent>
                            <Flex ml={6} justifyContent="space-between" w="100%"
                                  flexDirection={{base: 'column', md: 'row'}}>
                                <Heading
                                    size={"xl"}
                                    color={useColorModeValue('gray.300', 'gray.400')}
                                    lineHeight="tall"
                                    marginBottom={isSmallScreen ? 3 : 4}
                                    position="relative"
                                    transition="transform 0.3s, font-size 0.3s"
                                    _hover={{
                                        transform: 'scale(1.1)',
                                        _after: {
                                            width: isSmallScreen ? '95%' : '100%',
                                        }
                                    }}
                                    _after={{
                                        content: '""',
                                        position: 'absolute',
                                        width: '0',
                                        height: '1px',
                                        bottom: '-5px',
                                        left: isSmallScreen ? '2' : '0',
                                        backgroundColor: 'black',
                                        transition: 'width 0.420s ease-in-out',
                                    }}>
                                    {t("NewClient")}
                                </Heading>
                                <IconButton
                                    aria-label="Rotate Right"
                                    icon={<FontAwesomeIcon icon={faRotateRight}/>}
                                    colorScheme="black"
                                    variant="solid"
                                    size="md"
                                    mb={isSmallScreen ? 3 : 0}
                                />
                            </Flex>
                            <Divider color="gray.300" width="100%"/>
                            <ModalCloseButton
                                position="absolute"
                                top="1rem"
                                right="1rem"
                                bg="transparent"
                                _hover={{bg: 'transparent'}}
                                _active={{bg: 'transparent'}}
                                sx={{
                                    '& svg': {
                                        transition: 'all 0.2s ease-in-out',
                                    },
                                    '& svg path': {
                                        stroke: 'white',
                                        transition: 'stroke 0.3s ease-in-out',
                                    },
                                    '&:hover svg path': {
                                        stroke: 'red',
                                        transition: 'stroke 0.5s ease-in-out 0s',
                                    },
                                }}/>
                            <NewClientComponent formData={clientFormData} callback={handleClientFormSubmit}
                                                onClose={handleCloseModal}/>
                        </ModalContent>
                    </Modal>
                    <FormControl id="type" mb={4}>
                        <FormLabel
                            fontSize={['md', 'lg', 'xl']}
                            color={labelColor}
                            transition="transform 0.3s, font-size 0.3s"
                            _hover={{
                                transform: 'scale(1.05)',
                                transformOrigin: 'left center',
                                _after: {
                                    width: '15%'
                                }
                            }}
                            _after={{
                                content: '""',
                                position: 'absolute',
                                width: '0',
                                height: '1px',
                                bottom: '-1px',
                                left: '0',
                                backgroundColor: 'black',
                                transition: 'width 0.420s ease-in-out',
                            }}
                        >
                            Type de RDV
                        </FormLabel>
                        <Select
                            name={"type"}
                            value={type}
                            onChange={(selectedOption) => setType(selectedOption)}
                            options={options}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    border: `3px solid ${borderColor}`,
                                    borderRadius: '1rem',
                                    "&:hover": {
                                        backgroundColor: hoverBgColor
                                    },
                                    boxShadow: "none",
                                }),
                                placeholder: (base) => ({
                                    ...base,
                                    color: placeholderColor
                                })
                            }}
                        />

                    </FormControl>
                    <InputComponent
                        label="Nom"
                        name="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        isRequired
                    />
                    <InputComponent
                        label="Adresse"
                        name="address"
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        isRequired
                    />
                    <InputComponent
                        label="Date"
                        name="date"
                        type="date"
                        value={date ? date.split('/').reverse().join('-') : ''}
                        onChange={(e) => {
                            const [year, month, day] = e.target.value.split("-");
                            setDate(`${day}/${month}/${year}`);
                        }}
                        isRequired
                    />

                    <InputComponent
                        label="Heure de début"
                        name="timeStart"
                        type="time"
                        value={timeStart}
                        onChange={(e) => setTimeStart(e.target.value)}
                        isRequired
                    />
                    <InputComponent
                        label="Heure de fin"
                        name="timeEnd"
                        type="time"
                        value={timeEnd}
                        onChange={(e) => setTimeEnd(e.target.value)}
                        isRequired
                    />

                    <InputComponent
                        label="Note"
                        name="note"
                        type="textArea"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        isRequired
                    />
                    <FormControl id="participants" mb={4} isRequired>
                        <FormLabel>Participants</FormLabel>
                        <AsyncSelect
                            isMulti
                            value={participants}
                            loadOptions={loadOptions}
                            onChange={(selectedOptions) =>
                                handleParticipantsChange(selectedOptions as OptionType[])
                            }
                            components={makeAnimated()}
                            placeholder="Type to search..."
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <ButtonComponent callback={handleSave} text={t("Enregistré")}/>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default NewAppointmentComponent;
