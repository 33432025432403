import React from "react";
import {Box, Button} from "@chakra-ui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface ShowButtonProps {
    status: boolean;
    onClick: () => void;
}

function ShowButtonComponent(props: ShowButtonProps) {
    const { t } = useTranslation();
    return (
        <Box onClick={(e) => e.stopPropagation()}>
            <Button
                leftIcon={<FontAwesomeIcon icon={props.status ? faEye : faEyeSlash} />}
                size="sm"
                borderRadius="full"
                boxShadow="md"
                bg="transparent"
                rounded={"2xl"}
                border={`2px solid black`}
                _hover={{ bg: 'lightGray' }}
                _focus={{ boxShadow: "none" }}
                p={4}
                onClick={props.onClick}
            >
                {props.status ? t('Show') : t('Hide')} {t('Documents')}
            </Button>
        </Box>
    );
}

export default ShowButtonComponent;
