import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
    Input,
    Box,
    Select,
    Button,
    IconButton,
    useColorModeValue,
    useTheme
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from "../../../common/ButtonComponent";
import TeamCarousel from './TeamCarousel';
import EmployeeCarousel from './EmployeeCarousel';
import ManageTeamsAccordion from './ManageTeamsAccordion';
import { SettingsIcon } from "@chakra-ui/icons";

interface Task {
    name: string;
    startDate: string;
    endDate: string;
    assignedTo: {
        type: 'person' | 'team';
        name: string;
        members?: string[];
    };
}

const employees = ['Jean Dupont', 'Claire Delacroix', 'Lucie', 'Julien', 'Marc', 'Alice Bernard'];

interface CreateTaskModalProps {
    isOpen: boolean;
    onClose: () => void;
    handleCreateTask: (newTask: Task) => void;
}

const CreateTaskModal: React.FC<CreateTaskModalProps> = ({ isOpen, onClose, handleCreateTask }) => {
    const { t } = useTranslation();
    const [teams, setTeams] = useState([
        { name: 'Equipe 1', members: ['Jean Dupont', 'Lucie', 'Julien'] },
        { name: 'Equipe 2', members: ['Claire Delacroix', 'Marc', 'Alice Bernard'] },
        { name: 'Equipe 3', members: ['Jean Dupont', 'Marc', 'Alice Bernard'] },
        { name: 'Equipe 4', members: ['Lucie', 'Julien', 'Claire Delacroix'] },
    ]);

    const [newTask, setNewTask] = useState<Task>({
        name: '',
        startDate: '',
        endDate: '',
        assignedTo: {
            type: 'person',
            name: '',
            members: [],
        },
    });

    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentTeamIndex, setCurrentTeamIndex] = useState(0);
    const [manageMode, setManageMode] = useState(false);

    const nextEmployee = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % employees.length);
    };

    const prevEmployee = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + employees.length) % employees.length);
    };

    const nextTeam = () => {
        setCurrentTeamIndex((prevIndex) => (prevIndex + 1) % teams.length);
    };

    const prevTeam = () => {
        setCurrentTeamIndex((prevIndex) => (prevIndex - 1 + teams.length) % teams.length);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewTask({ ...newTask, [name]: value });
    };

    const handleAssignedToChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        setNewTask({
            ...newTask,
            assignedTo: { ...newTask.assignedTo, type: value as 'person' | 'team' },
        });
    };

    const toggleManageMode = () => {
        setManageMode(!manageMode);
    };

    const handleTeamSelect = (teamIndex: number) => {
        const selectedTeam = teams[teamIndex];
        setNewTask({
            ...newTask,
            assignedTo: { type: 'team', name: selectedTeam.name, members: selectedTeam.members },
        });
    };

    const handleEmployeeSelect = (employeeIndex: number) => {
        const selectedEmployee = employees[employeeIndex];
        setNewTask({
            ...newTask,
            assignedTo: { type: 'person', name: selectedEmployee },
        });
    };

    const handleDeleteTeam = (teamIndex: number) => {
        const updatedTeams = teams.filter((_, i) => i !== teamIndex);
        setTeams(updatedTeams);
    };

    const handleToggleMember = (teamIndex: number, employee: string) => {
        const team = teams[teamIndex];
        const isMember = team.members.includes(employee);
        const newMembers = isMember
            ? team.members.filter(m => m !== employee)
            : [...team.members, employee];
        const updatedTeams = [...teams];
        updatedTeams[teamIndex].members = newMembers;
        setTeams(updatedTeams);
    };

    const handleBack = () => {
        setManageMode(false);
    };

    const handleCreateTeam = () => {
        const newTeam = { name: `${t('Team')} ${teams.length + 1}`, members: [] as string[] };
        setTeams([...teams, newTeam]);
    };

    const handleCreate = () => {
        handleCreateTask(newTask);
        onClose();
    };

    const inputBorderColor = useColorModeValue('gray.300', 'gray.600');
    const buttonHoverColor = useColorModeValue('gray.200', 'gray.700');
    const modalBg = useColorModeValue('white', 'gray.800');

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg={modalBg} borderRadius="md" boxShadow="xl" transition="all 0.3s ease-in-out">
                <ModalHeader fontWeight="bold" fontSize="xl">{t('Nt')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box width="100%">
                        <SimpleGrid columns={2} spacing={9} mb={6}>
                            <Input
                                placeholder={t('Nom')}
                                name="name"
                                value={newTask.name}
                                onChange={handleInputChange}
                                variant="flushed"
                                borderBottomColor={inputBorderColor}
                                _hover={{ borderBottomColor: buttonHoverColor }}
                            />
                            <Box>
                                <Input
                                    placeholder={t('Begin')}
                                    name="startDate"
                                    type="date"
                                    value={newTask.startDate}
                                    onChange={handleInputChange}
                                    variant="flushed"
                                    borderBottomColor={inputBorderColor}
                                    _hover={{ borderBottomColor: buttonHoverColor }}
                                />
                            </Box>
                            <Select
                                variant="flushed"
                                name="assignedToType"
                                onChange={handleAssignedToChange}
                                value={newTask.assignedTo.type}
                                borderBottomColor={inputBorderColor}
                                _hover={{ borderBottomColor: buttonHoverColor }}
                            >
                                <option value="person">{t('Person')}</option>
                                <option value="team">{t('Team')}</option>
                            </Select>
                            <Box>
                                <Input
                                    placeholder={t('End')}
                                    name="endDate"
                                    type="date"
                                    value={newTask.endDate}
                                    onChange={handleInputChange}
                                    variant="flushed"
                                    borderBottomColor={inputBorderColor}
                                    _hover={{ borderBottomColor: buttonHoverColor }}
                                />
                            </Box>
                        </SimpleGrid>

                        {newTask.assignedTo.type === 'team' && !manageMode && (
                            <IconButton
                                aria-label={t('ManageTeams')}
                                icon={<SettingsIcon />}
                                onClick={toggleManageMode}
                                ml="auto"
                                p={2}
                                variant="ghost"
                                _hover={{ bg: buttonHoverColor }}
                            />
                        )}

                        {newTask.assignedTo.type === 'team' && !manageMode ? (
                            <TeamCarousel
                                teams={teams}
                                currentTeamIndex={currentTeamIndex}
                                prevTeam={prevTeam}
                                nextTeam={nextTeam}
                                handleTeamSelect={handleTeamSelect}
                                selectedTeamName={newTask.assignedTo.name}
                            />
                        ) : manageMode && newTask.assignedTo.type === 'team' ? (
                            <ManageTeamsAccordion
                                teams={teams}
                                employees={employees}
                                handleDeleteTeam={handleDeleteTeam}
                                handleToggleMember={handleToggleMember}
                                onBack={handleBack}
                                onCreateTeam={handleCreateTeam}
                            />
                        ) : (
                            <EmployeeCarousel
                                employees={employees}
                                currentIndex={currentIndex}
                                prevEmployee={prevEmployee}
                                nextEmployee={nextEmployee}
                                handleEmployeeSelect={handleEmployeeSelect}
                                selectedEmployeeName={newTask.assignedTo.name}
                            />
                        )}
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <ButtonComponent text={t('CreateTask')} callback={handleCreate} />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreateTaskModal;
