import React, {useEffect, useState} from "react";
import {Input, InputGroup, InputLeftElement, useColorModeValue} from "@chakra-ui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

interface SearchProps {
    value: string;
    onChange: (term: string) => void;
}

function SearchComponent(props: SearchProps) {
    const { t } = useTranslation();
    const [isHovering, setIsHovering] = useState(false);
    const [restartAnimation, setRestartAnimation] = useState(false);
    const borderColor = useColorModeValue('black', 'gray');
    const hoverBgColor = useColorModeValue('#A3A3A3', 'gray.800');
    const placeholderColor = useColorModeValue('black', 'gray.500');
    const placeholderText = t("Search");
    const animatedPlaceholder = useTypewriterEffect(isHovering ? placeholderText : '', restartAnimation, 100);

    function useTypewriterEffect(text: string, restart: boolean, speed = 150): string {
        const [index, setIndex] = useState(0);
        const [currentText, setCurrentText] = useState('');

        useEffect(() => {
            if (index < text.length) {
                const timerId = setTimeout(() => {
                    setCurrentText((prev) => prev + text.charAt(index));
                    setIndex(index + 1);
                }, speed);

                return () => clearTimeout(timerId);
            }
        }, [index, text, speed]);

        useEffect(() => {
            if (restart) {
                setCurrentText('');
                setIndex(0);
            }
        }, [restart]);

        return currentText;
    }
    const handleMouseEnter = () => {
        setIsHovering(true);
        setRestartAnimation(prev => !prev);
    };

    return (
        <InputGroup
            onMouseEnter={handleMouseEnter}
            onMouseLeave={() => setIsHovering(false)}>
            <InputLeftElement
                paddingTop={2}
                pointerEvents="none"
                children={<FontAwesomeIcon icon={faSearch} color={borderColor}/>}
                sx={{
                    '::after': {
                        content: '""',
                        display: 'block',
                        height: '108%',
                        width: '2px',
                        bgColor: 'black',
                        position: 'absolute',
                        right: 0,
                        top: '1',
                        backgroundColor: borderColor,
                    }
                }}
            />
            <Input
                variant="outline"
                placeholder={isHovering ? animatedPlaceholder : placeholderText}
                onChange={(e) => props.onChange(e.target.value)}
                value={props.value}
                size="lg"
                rounded={"3xl"}
                bg={useColorModeValue('gray.300', 'gray.700')}
                border={`3px solid ${borderColor}`}
                _hover={{bg: hoverBgColor}}
                _focus={{borderColor: borderColor}}
                _placeholder={{color: placeholderColor}}
            />
        </InputGroup>
    )
}

export default SearchComponent;