import React from "react";
import { Box, Heading, Text, Divider, Flex, useColorModeValue, useBreakpointValue, Stack } from "@chakra-ui/react";
import { IEvent } from "../../screens/CalendarScreen";
import { IAppointment } from "./CalendarComponent";

interface SmallScreenAgendaProps {
    events: IAppointment[];
}

const formatDate = (date: string) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString("fr-FR", { weekday: "long", day: "numeric", month: "short" });
};

const getCurrentDateTime = () => {
    return new Date().toISOString();
};

const isDateTimePast = (dateTime: string): boolean => {
    return new Date(dateTime) < new Date();
};

const getColorStyle = (type: string) => {
    switch (type) {
        case "Project":
        case "project":
            return { borderColor: "#3182CE", backgroundColor: "rgba(49, 130, 206, 0.1)" };
        case "Banque":
        case "banque":
            return { borderColor: "#D69E2E", backgroundColor: "rgba(214, 158, 46, 0.1)" };
        case "Reunion":
        case "reunion":
            return { borderColor: "#38A169", backgroundColor: "rgba(56, 161, 105, 0.1)" };
        case "RDV":
        case "rendez-vous":
            return { borderColor: "#9F7AEA", backgroundColor: "rgba(159, 122, 234, 0.1)" };
        default:
            return { borderColor: "#CBD5E0", backgroundColor: "rgba(203, 213, 224, 0.1)" };
    }
};

const SmallScreenAgenda: React.FC<SmallScreenAgendaProps> = ({ events }) => {
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const currentDateTime = getCurrentDateTime();

    const futureEvents = events
        .filter(event => !isDateTimePast(`${event.date}T${event.timeStart}`))
        .sort((a, b) => new Date(`${a.date}T${a.timeStart}`).getTime() - new Date(`${b.date}T${b.timeStart}`).getTime());

    const eventsByDate = futureEvents.reduce((acc: { [key: string]: IAppointment[] }, event: IAppointment) => {
        if (!acc[event.date]) {
            acc[event.date] = [];
        }
        acc[event.date].push(event);
        return acc;
    }, {});

    const sortedDates = Object.keys(eventsByDate).sort();

    return (
        <Box
            bg={useColorModeValue("gray.200", "gray.700")}
            width={isSmallScreen ? 'calc(100% - 10px)' : "50%"}
            rounded="md"
            boxShadow="md"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            textAlign="center"
            padding={isSmallScreen ? 3 : 10}
            marginX={isSmallScreen ? 0 : 10}
            my={isSmallScreen ? 3 : 5}
        >
            <Flex
                justifyContent="space-between"
                w="100%"
                flexDirection={{ base: 'column', md: 'row' }}
                marginBottom={isSmallScreen ? 3 : 5}
            >
                <Heading
                    color={useColorModeValue('gray.600', 'gray.800')}
                    lineHeight="tall"
                    marginBottom={isSmallScreen ? 3 : 0}
                    position="relative"
                    transition="transform 0.3s, font-size 0.3s"
                    _hover={{
                        transform: 'scale(1.1)',
                        _after: {
                            width: isSmallScreen ? '95%' : '100%',
                        }
                    }}
                    _after={{
                        content: '""',
                        position: 'absolute',
                        width: '0',
                        height: '1px',
                        bottom: '-5px',
                        left: isSmallScreen ? '2' : '0',
                        backgroundColor: 'black',
                        transition: 'width 0.420s ease-in-out',
                    }}>
                    Agenda
                </Heading>
            </Flex>
            <Box marginTop={5}
                 maxHeight="600px"
                 overflowY="auto">
                <Stack spacing="4" align="stretch" paddingX={5} overflowY={"auto"} className={"custom-scrollbar"} width="100%">
                    {sortedDates.map(date => (
                        <Box key={date}>
                            <Text textAlign="left" mt={2} color={date === currentDateTime.split('T')[0] ? 'red' : useColorModeValue('gray.500', 'gray.400')} fontSize='lg'>
                                {date}
                            </Text>
                            {eventsByDate[date].map((event) => {
                                const { borderColor, backgroundColor } = getColorStyle(event.type);
                                return (
                                    <Box key={event._id} position="relative" my={4}>
                                        <Divider borderColor={"#484848"} backgroundColor={"#484848"} width="97%" height="2px" ml={2} mt={3} mb={3} />
                                        <Flex direction="row" justifyContent="space-between" align="center" width="90%"
                                              sx={{
                                                  transition: 'transform .2s',
                                                  _hover: {
                                                      transform: 'scale(1.05)',
                                                      cursor: 'pointer'
                                                  }
                                              }}>
                                            <Box position="relative">
                                                <Box rounded="md" boxShadow="md" width="4px" height="100%" bg={backgroundColor} position="absolute" left={0} style={{ borderLeft: `4px solid ${borderColor}` }} />
                                                <Text ml={0} mt={2} mb={4} pl={6} color="gray.600" fontWeight="bold">{event.type}</Text>
                                            </Box>
                                            <Flex direction="column" textAlign="right">
                                                <Text fontSize='xs' color="gray.500">{event.timeStart}</Text>
                                                <Text fontSize='xs' color="gray.500">{event.timeEnd}</Text>
                                            </Flex>
                                        </Flex>
                                    </Box>
                                );
                            })}
                        </Box>
                    ))}
                </Stack>
            </Box>
        </Box>
    );
};

export default SmallScreenAgenda;
