import React, { useState } from "react";
import {
    Box,
    Collapse,
    Flex,
    Stack,
    Tag,
    TagLabel,
    Text,
    useColorModeValue,
    useBreakpointValue,
    useColorMode,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { IAppointment } from "./AppointmentComponent";

interface RdvDetaileProps {
    events: IAppointment[];
}

const RdvDetaile: React.FC<RdvDetaileProps> = ({ events }) => {
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const [searchTerm, setSearchTerm] = useState('');
    const [projects, setProjects] = useState([]);
    const { colorMode } = useColorMode();
    const paddingSize = useBreakpointValue({ base: '1', md: '2' });
    const { t } = useTranslation();
    const MotionBox = motion(Box);
    const [showDetails, setShowDetails] = useState<{ [key: string]: boolean }>({});

    const getTagColors = (type: string) => {
        let borderColor = "#CBD5E0";
        let backgroundColor = "rgba(203, 213, 224, 0.4)";

        switch (type) {
            case "project":
                return { borderColor: "#3182CE", backgroundColor: "rgba(49, 130, 206, 0.1)" };
            case "banque":
                return { borderColor: "#D69E2E", backgroundColor: "rgba(214, 158, 46, 0.1)" };
            case "reunion":
                return { borderColor: "#38A169", backgroundColor: "rgba(56, 161, 105, 0.1)" };
            case "rendez-vous":
                return { borderColor: "#9F7AEA", backgroundColor: "rgba(159, 122, 234, 0.1)" };
            default:
                borderColor = "#CBD5E0";
                backgroundColor = "rgba(203, 213, 224, 0.4)";
                break;
        }

        return { borderColor, backgroundColor };
    };

    const toggleDetails = (id: string) => {
        setShowDetails(prevDetails => ({
            ...prevDetails,
            [id]: !prevDetails[id]
        }));
    };

    const getDateTime = (date: string, time: string) => {
        const [year, month, day] = date.split("-").map(num => parseInt(num));
        const [hour, minute] = time.split(":").map(num => parseInt(num));
        return new Date(year, month - 1, day, hour, minute);
    };

    const formatDate = (date: string) => {
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
    };

    const sortedRdvs = [...events].sort((a, b) =>
        getDateTime(a.date, a.timeStart).getTime() - getDateTime(b.date, b.timeEnd).getTime()
    );

    return (
        <Box
            marginTop={5}
            maxHeight="600px"
            overflowY="auto"
            w="400px"
            mx="auto"
            textAlign="center"
        >
            <Stack spacing="4" align="center" paddingX={5} overflowY={"auto"} className={"custom-scrollbar"}
                   width="100%">
                {sortedRdvs.map((rdv) => {
                    const { borderColor, backgroundColor } = getTagColors(rdv.type);
                    return (
                        <Box
                            key={rdv._id}
                            color="gray.600"
                            borderColor={borderColor}
                            borderWidth="3px"
                            borderRadius="lg"
                            onClick={() => toggleDetails(rdv._id)}
                            cursor="pointer"
                            p={4}
                            mx="auto"
                            width="100%"
                            rounded="md"
                            boxShadow="md"
                            bg={backgroundColor}
                        >
                            <Flex
                                bg={"none"}
                                w="100%"
                                justifyContent="space-between"
                                flexDirection="row"
                                padding={2}
                            >
                                <Flex flexDirection="column">
                                    <Flex justifyContent="space-between" marginBottom={2}>
                                        <Text color="gray.500" textAlign="left" fontSize={"xl"}
                                              marginRight={2}>{rdv.name}</Text>
                                    </Flex>
                                    <Tag size="lg" borderRadius="full" borderColor={borderColor}
                                         backgroundColor={backgroundColor}>
                                        <Flex align="center" justify="center" w="100%">
                                            <TagLabel>{rdv.type}</TagLabel>
                                        </Flex>
                                    </Tag>
                                </Flex>
                                <Flex flexDirection="column">
                                    <Text color="gray.500" textAlign="right" fontSize={"lg"}>
                                        {rdv.date}
                                    </Text>
                                    <Text color="gray.500" textAlign="right"
                                          fontSize={"lg"}>{rdv.timeStart} - {rdv.timeEnd}</Text>
                                </Flex>
                            </Flex>
                            <Collapse in={showDetails[rdv._id]}>
                                <MotionBox
                                    initial={{ scaleY: 0 }}
                                    animate={{ scaleY: 1 }}
                                    exit={{ scaleY: 0 }}
                                    transition={{ duration: 0.5 }}
                                    transformOrigin="top"
                                >
                                    <Flex
                                        marginTop={isSmallScreen ? 3 : 2}
                                        w="100%"
                                        rounded="md"
                                        boxShadow="md"
                                        flexDirection="column"
                                        justifyContent="space-between"
                                        padding={2}
                                    >
                                        <Text color="gray.500" textAlign="left" textDecoration="underline"
                                              fontSize={"xl"}>Infos :</Text>
                                        <Flex
                                            marginTop={isSmallScreen ? 3 : 2}
                                            w="100%"
                                            flexDirection={isSmallScreen ? "column" : "row"}
                                            justifyContent="space-between"
                                            padding={2}
                                        >
                                            <Flex flexDirection="column">
                                                <Text color="gray.500" textAlign={isSmallScreen ? "left" : "right"}
                                                      fontSize={"lg"}>Lieu: {rdv.phone}</Text>
                                                <Text color="gray.500" textAlign={isSmallScreen ? "left" : "right"}
                                                      fontSize={"lg"}>Note: {rdv.email}</Text>
                                            </Flex>
                                            <Flex flexDirection="column">
                                                <Text color="gray.500" textAlign="left"
                                                      fontSize={"lg"}>{rdv.address}</Text>
                                                <Text color="gray.500" textAlign="left"
                                                      fontSize={"lg"}>{rdv.note}</Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </MotionBox>
                            </Collapse>
                        </Box>
                    );
                })}
            </Stack>
        </Box>
    );
};

export default RdvDetaile;
