import React from 'react';
import { Avatar, AvatarGroup, Flex, IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface TeamCarouselProps {
    teams: { name: string, members: string[] }[];
    currentTeamIndex: number;
    prevTeam: () => void;
    nextTeam: () => void;
    handleTeamSelect: (teamIndex: number) => void;
    selectedTeamName: string;
}

const TeamCarousel: React.FC<TeamCarouselProps> = ({ teams, currentTeamIndex, prevTeam, nextTeam, handleTeamSelect, selectedTeamName }) => {
    return (
        <Flex justifyContent="center" alignItems="center" mx="auto" my={4}>
            <IconButton icon={<ChevronLeftIcon />} onClick={prevTeam} aria-label="Previous Team" />

            <Popover trigger="hover" placement="top">
                <PopoverTrigger>
                    <Avatar
                        name={teams[currentTeamIndex].name}
                        size="2xl"
                        mx={4}
                        onClick={() => handleTeamSelect(currentTeamIndex)}
                        border={selectedTeamName === teams[currentTeamIndex].name ? '3px solid black' : 'none'}
                    />
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverBody>
                        <Text fontWeight="bold">Membres de l'équipe :</Text>
                        <AvatarGroup size="sm" max={4}>
                            {teams[currentTeamIndex].members.map((member, index) => (
                                <Avatar key={index} name={member} />
                            ))}
                        </AvatarGroup>
                    </PopoverBody>
                </PopoverContent>
            </Popover>

            <IconButton icon={<ChevronRightIcon />} onClick={nextTeam} aria-label="Next Team" />
        </Flex>
    );
};

export default TeamCarousel;
