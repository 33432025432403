import React, { useEffect, useState } from "react";
import {
    Box,
    Divider,
    Heading,
    Button,
    Stack,
    Flex,
    useColorModeValue,
    useBreakpointValue,
    Spinner,
    Input,
    InputGroup,
    InputRightElement, IconButton,
} from "@chakra-ui/react";
import {CloseIcon, SearchIcon} from "@chakra-ui/icons";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import NavBarComponent from "../navigation/NavBarComponent";
import './MapScreen.css';
import axios from "axios";
import { getAuthorizedHeader } from "../../common/auth";
import {parseDMS} from "../project/projectDetails/MapComponent";
import { motion } from "framer-motion";
import { FaFilter } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const MotionFlex = motion(Flex);


interface Project {
    reference: string;
    latitude: string;
    longitude: string;
    status: string;
}

const getStatusIcon = (status: string) => {
    let color;
    switch (status) {
        case "COMPLETED":
            color = "green";
            break;
        case "IN PROGRESS":
            color = "orange";
            break;
        case "TO DO":
            color = "yellow";
            break;
        case "DELAYED":
            color = "red";
            break;
        default:
            color = "blue";
            break;
    }
    return L.divIcon({
        className: "custom-icon",
        html: `
            <svg width="35" height="35" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill="${color}" stroke="black" stroke-width="1"
                      d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
            </svg>
        `,
        iconSize: [24, 24],
        iconAnchor: [12, 24],
        popupAnchor: [0, -24],
    });
};

const getPopupClass = (status: string) => {
    switch (status) {
        case "COMPLETED":
            return "popup-COMPLETED";
        case "IN PROGRESS":
            return "popup-in-progress";
        case "TO DO":
            return "popup-TO DO";
        default:
            return "";
    }
};

const MapScreen: React.FC = () => {
    const { t } = useTranslation();
    const toggleFilter = () => setIsExpanded(!isExpanded);
    const [isExpanded, setIsExpanded] = useState(false);
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const [projects, setProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState(true);
    const [filteredStatuses, setFilteredStatuses] = useState<string[]>(["COMPLETED", "IN PROGRESS", "TO DO", "DELAYED"]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get('/api/projects/coordinate', { headers: getAuthorizedHeader() });
                setProjects(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching projects:", error);
                setLoading(false);
            }
        };
        fetchProjects();
    }, []);

    const handleStatusChange = (status: string) => {
        setFilteredStatuses(prevStatuses =>
            prevStatuses.includes(status)
                ? prevStatuses.filter(s => s !== status)
                : [...prevStatuses, status]
        );
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    if (loading) {
        return (
            <Flex align="center" justify="center" height="100vh">
                <Spinner size="xl" />
            </Flex>
        );
    }

    const defaultCenter: [number, number] = projects.length > 0
        ? [parseDMS(projects[0].latitude), parseDMS(projects[0].longitude)]
        : [46.603354, 1.888334];

    function getStatusColor(status: string) {
        if (status === 'COMPLETED') {
            return 'green';
        } else if (status === 'IN PROGRESS') {
            return 'orange';
        } else if (status === 'DELAYED') {
            return 'red';
        }
        return 'yellow';
    }

    const filteredProjects = projects.filter(project =>
        filteredStatuses.includes(project.status) &&
        project.reference.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box position="relative" height="100vh" width="100%">
            <Flex zIndex={4} position="fixed" top="0" left="0" right="0" >
                <NavBarComponent />
            </Flex>
            <MotionFlex
                mt={6}
                mr={3}
                maxW="600px"
                align="center"
                justify="flex-start"
                flexDirection="column"
                p={isExpanded ? 2 : 0}
                bg={isExpanded ? "rgba(255, 255, 255, 0.8)" : "transparent"}
                borderRadius="md"
                boxShadow={isExpanded ? "lg" : "none"}
                zIndex={2}
                position="fixed"
                top="80px"
                right="0"
                initial={{ width: "50px" }}
                animate={{ width: isExpanded ? "100%" : "50px" }}
                transition={{ duration: 0.5 }}
            >
                {!isExpanded && (
                    <IconButton
                        aria-label="Filter"
                        icon={<FaFilter />}
                        onClick={toggleFilter}
                        color="gray.600"
                        bg="white"
                        borderRadius="full"
                        boxSize="50px"
                        _hover={{
                            bg: "gray.200",
                            color: "teal.500",
                            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                        }}
                        _active={{
                            bg: "gray.300",
                            boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.2)",
                        }}
                        _focus={{
                            boxShadow: "0 0 0 2px teal.400",
                        }}
                        transition="all 0.3s ease"
                    />
                )}

                {isExpanded && (
                    <>
                        <Flex direction="row" align="center" mt={{ base: 6, md: 4 }} mb={4} gap={2}>
                            <InputGroup maxW="250px">
                                <Input
                                    placeholder="Search by reference"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    borderColor="gray.300"
                                    _placeholder={{ color: 'gray.500' }}
                                    _hover={{ borderColor: 'gray.400' }}
                                    _focus={{ borderColor: 'blue.500' }}
                                    bg="white"
                                    borderRadius="full"
                                />
                                <InputRightElement>
                                    <SearchIcon color="gray.500" />
                                </InputRightElement>
                            </InputGroup>

                            <IconButton
                                aria-label="Close filter"
                                icon={<CloseIcon />}
                                onClick={toggleFilter}
                                color="gray.500"
                                borderRadius="full"
                                bg="transparent"
                                _hover={{
                                    color: "red.500",
                                    bg: "transparent",
                                }}
                                _focus={{ boxShadow: "none" }}
                                size="lg"
                                position="absolute"
                                top="10px"
                                right="10px"
                                zIndex={3}
                                sx={{
                                    '& svg': {
                                        transition: 'all 0.2s ease-in-out',
                                    },
                                    '& svg path': {
                                        stroke: 'gray',
                                        transition: 'stroke 0.3s ease-in-out',
                                    },
                                    '&:hover svg path': {
                                        stroke: 'red',
                                        transition: 'stroke 0.5s ease-in-out 0s',
                                    },
                                }}
                            />

                        </Flex>
                        <Stack
                            direction={{ base: "row", md: "row" }}
                            spacing={{ base: 2, md: 4 }}
                            align="center"
                            justify="center"
                            flexWrap="wrap"
                        >
                            {["COMPLETED", "IN PROGRESS", "TO DO", "DELAYED"].map(status => (
                                <Button
                                    key={status}
                                    variant="solid"
                                    colorScheme={filteredStatuses.includes(status) ? getStatusColor(status) : "gray"}
                                    color="white"
                                    fontWeight="medium"
                                    onClick={() => handleStatusChange(status)}
                                    _hover={{
                                        bg: filteredStatuses.includes(status) ? `${getStatusColor(status)}.600` : "gray.400",
                                    }}
                                    _active={{
                                        bg: filteredStatuses.includes(status) ? `${getStatusColor(status)}.700` : "gray.500",
                                    }}
                                    borderRadius="full"
                                    px={4}
                                    py={2}
                                    transition="all 0.3s ease"
                                >
                                    {status.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                                </Button>
                            ))}
                        </Stack>
                    </>
                )}
            </MotionFlex>
            <Box
                position="absolute"
                top="80px"
                bottom="0"
                left="0"
                right="0"
                zIndex={1}
            >
                <MapContainer
                    center={defaultCenter}
                    zoom={6}
                    style={{ height: "100%", width: "100%" }}
                >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    {filteredProjects.map((project, index) => (
                        <Marker
                            key={index}
                            position={[parseDMS(project.latitude), parseDMS(project.longitude)]}
                            icon={getStatusIcon(project.status)}
                        >
                            <Popup className={getPopupClass(project.status)}>
                                <strong>{project.reference}</strong> <br />
                                Latitude: {project.latitude} <br />
                                Longitude: {project.longitude}
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </Box>
        </Box>
    );
}

export default MapScreen;
