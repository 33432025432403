import React, { useEffect, useState } from "react";
import {
    Box,
    Heading,
    useBreakpointValue,
    useColorModeValue,
    Flex,
    Image,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getAuthorizedHeader } from "../../common/auth";
import TaskDetailsComponent from "./projectDetails/TaskDetailsComponent";
import ProjectModalComponent from "./ProjectModalComponent";
import TaskModalComponent from "./projectDetails/TaskModalComponent";
import SearchComponent from "../common/SearchComponent";
import PageLoadingSpinner from "../common/PageLoadingSpinner";

interface Task {
    _id: string;
    projectId: string;
    taskName: string;
    status: string;
}

interface TasksSummaryProps {}

function TasksSummaryComponent(props: TasksSummaryProps) {
    const [tasks, setTasks] = useState<Task[]>([]);
    const [projectReferences, setProjectReferences] = useState<{ [key: string]: string }>({});
    const [loadingProjects, setLoadingProjects] = useState<{ [key: string]: boolean }>({});
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const { t } = useTranslation();
    const [selectedTask, setSelectedTask] = useState<Task | null>(null);
    const [selectedProject, setSelectedProject] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const getTasks = () => {
        axios
            .get('/api/tasks/me', { headers: getAuthorizedHeader() })
            .then((response) => setTasks(response.data))
            .catch((error) => console.error("Error fetching tasks:", error));
    };

    const fetchProjectReference = async (projectId: string) => {
        if (projectReferences[projectId] || loadingProjects[projectId]) return;

        setLoadingProjects((prev) => ({ ...prev, [projectId]: true }));

        try {
            const response = await axios.get(`/api/project/${projectId}/reference`, {
                headers: getAuthorizedHeader(),
            });
            setProjectReferences((prev) => ({ ...prev, [projectId]: response.data }));
        } catch (error) {
            console.error(`Error fetching reference for project ${projectId}:`, error);
            setProjectReferences((prev) => ({ ...prev, [projectId]: `Loading...` }));
        } finally {
            setLoadingProjects((prev) => ({ ...prev, [projectId]: false }));
        }
    };

    const handleOpenModal = async (task: Task) => {
        setSelectedTask(task);
        setIsLoading(true);
        await getProject(task.projectId);
        setIsOpen(true);
        setIsLoading(false);
    };

    const getProject = async (projectId: string) => {
        try {
            const response = await axios.get(`/api/project/${projectId}`, {
                headers: getAuthorizedHeader(),
            });
            setSelectedProject(response.data);
        } catch (error) {
            console.error("Error fetching Project:", error);
        }
    };

    const getProjectIcon = (projectId: string) => {
        const projectName = projectReferences[projectId]?.toLowerCase();
        if (projectName?.includes("arkolia")) {
            return "https://arkolia.com/wp-content/uploads/2023/11/favicon_ARKOLIA-512x512px.png";
        }
        if (projectName?.includes("volta")) {
            return "https://www.groupe-volta.com/wp-content/uploads/2021/05/Picto-volta-bleu.svg";
        }
        return "https://cdn-icons-png.flaticon.com/512/430/430422.png";
    };

    useEffect(() => {
        getTasks();
    }, []);

    useEffect(() => {
        const projectIds = [...new Set(tasks.map((task) => task.projectId))];
        projectIds.forEach((projectId) => fetchProjectReference(projectId));
    }, [tasks]);

    const groupedTasks = tasks.reduce((acc: { [key: string]: Task[] }, task: Task) => {
        if (!acc[task.projectId]) acc[task.projectId] = [];
        acc[task.projectId].push(task);
        return acc;
    }, {});

    const filteredProjects = Object.keys(groupedTasks).filter((projectId) => {
        const projectTasks = groupedTasks[projectId];
        return projectTasks.some(
            (task) =>
                task.projectId.toLowerCase().includes(searchTerm.toLowerCase()) ||
                task.taskName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const handleCloseModal = () => {
        setIsOpen(false);
        setSelectedProject(null);
    };

    return (
        <Box
            bg={useColorModeValue("gray.200", "gray.700")}
            rounded="md"
            boxShadow="md"
            width={isSmallScreen ? 'calc(100% - 25px)' : "50%"}
            padding={isSmallScreen ? 3 : 10}
            mt={useBreakpointValue({ base: '10%', md: 5 })}
            mb={isSmallScreen ? 3 : 10}
            mr={isSmallScreen ? 3 : 5}
        >
            <Box width="100%">
                <Flex justifyContent="center" w="100%" marginBottom={5}>
                    <Heading
                        lineHeight="tall"
                        marginBottom={isSmallScreen ? 3 : 0}
                        position="relative"
                        transition="transform 0.3s, font-size 0.3s"
                        _hover={{
                            transform: 'scale(1.1)',
                            _after: {
                                width: isSmallScreen ? '95%' : '100%',
                            }
                        }}
                        _after={{
                            content: '""',
                            position: 'absolute',
                            width: '0',
                            height: '1px',
                            bottom: '-5px',
                            left: isSmallScreen ? '2' : '0',
                            backgroundColor: 'black',
                            transition: 'width 0.420s ease-in-out',
                        }}>{t("Tasks")}
                    </Heading>
                </Flex>
                <SearchComponent value={searchTerm} onChange={(e) => setSearchTerm(e)} />
                <Box
                    overflowY="auto"
                    maxH="70vh"
                    bg={useColorModeValue("white", "gray.800")}
                    boxShadow="md"
                    p={4}
                    mt={5}
                    borderRadius="lg"
                >
                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        {filteredProjects.map((projectId) => (
                            <Box key={projectId} mb={6}>
                    <Heading
                        lineHeight="tall"
                        marginBottom={isSmallScreen ? 3 : 0}
                        position="relative"
                        transition="transform 0.3s, font-size 0.3s"
                        _hover={{
                            transform: 'scale(1.1)',
                        }}
                    >
                        <Flex justifyContent="center" alignItems="center" minH="1.5em">
                            <Image
                                src={getProjectIcon(projectId)}
                                alt="Project Icon"
                                boxSize="24px"
                                mr={2}
                            />
                            {projectReferences[projectId] || <PageLoadingSpinner />}
                        </Flex>
                    </Heading>

                                {groupedTasks[projectId].map((task) => (
                                    <Box key={task._id} mb={4} onClick={() => handleOpenModal(task)}>
                                        <TaskDetailsComponent
                                            idProject={task.projectId}
                                            idTask={task._id}
                                            task={task.taskName}
                                            status={task.status}
                                            callback={getTasks}
                                            data={false}
                                        />
                                    </Box>
                                ))}
                            </Box>
                    ))}
                    </ul>
                </Box>
            </Box>
            {!isLoading && selectedProject && (
                <ProjectModalComponent
                    selectedProject={selectedProject}
                    handleCloseModal={handleCloseModal}
                    callback={getTasks}
                />
            )}
            {!isLoading && selectedTask && (
                <TaskModalComponent
                    isOpen={isOpen}
                    handleCloseModal={() => setIsOpen(false)}
                    handleCallback={getTasks}
                    idProject={selectedTask.projectId}
                    idTask={selectedTask._id}
                    task={selectedTask.taskName}
                    status={selectedTask.status}
                />
            )}
        </Box>
    );
}

export default TasksSummaryComponent;
