import React, {useEffect, useState} from "react";
import {
    Box,
    calc,
    Heading,
    Th,
    Thead,
    Tr,
    useBreakpointValue,
    useColorModeValue,
    TableContainer,
    Table, Flex
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import ProjectSummaryDetailsComponent from "./projectSummary/ProjectSummaryDetailsComponent";
import SearchComponent from "../common/SearchComponent";
import axios from "axios";
import {getAuthorizedHeader} from "../../common/auth";
import ProjectModalComponent from "./ProjectModalComponent";

interface ProjectsSummaryProps {

}

function ProjectsSummaryComponent(props: ProjectsSummaryProps) {
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const [searchTerm, setSearchTerm] = useState('');
    const [projects, setProjects] = useState([]);
    const paddingSize = useBreakpointValue({base: '1', md: '2'});
    const [selectedProject, setSelectedProject] = useState(null);

    const {t} = useTranslation();

    const handleProjectClick = (project: any) => {
        setSelectedProject(project);
    };
    async function fetchProjects() {
        try {
            const response = await axios.get('/api/projects', {
                headers: getAuthorizedHeader(),
            });
            setProjects(response.data);
        } catch (error) {
            console.error('Error fetching Projects:', error);
        }
    }


    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <Box
            bg={useColorModeValue("gray.200", "gray.700")}
            rounded="md"
            boxShadow="md"
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
            textAlign="center"
            width={isSmallScreen ? 'calc("100% - 20px")' : "50%"}
            padding={isSmallScreen ? 3 : 10}
            ml={isSmallScreen ? 3 : 10}
            mb={isSmallScreen ? 3 : 10}
            mr={isSmallScreen ? 3 : 5}
            mt={isSmallScreen ? 0 : 5}>
            <Box width="100%">
                <Flex
                    justifyContent="center"
                    w="100%"
                    flexDirection={{base: 'column', md: 'row'}}
                    marginBottom={isSmallScreen ? 3 : 5}
                >
                    <Heading
                        lineHeight="tall"
                        marginBottom={isSmallScreen ? 3 : 0}
                        position="relative"
                        transition="transform 0.3s, font-size 0.3s"
                        _hover={{
                            transform: 'scale(1.1)',
                            _after: {
                                width: isSmallScreen ? '95%' : '100%',
                            }
                        }}
                        _after={{
                            content: '""',
                            position: 'absolute',
                            width: '0',
                            height: '1px',
                            bottom: '-5px',
                            left: isSmallScreen ? '2' : '0',
                            backgroundColor: 'black',
                            transition: 'width 0.420s ease-in-out',
                        }}>
                    {t("Projects")}
                    </Heading>
                </Flex>
                <SearchComponent value={searchTerm} onChange={(e) => setSearchTerm(e)}/>
                <Box
                    bg={useColorModeValue("white", "gray.800")}
                    overflowY="auto"
                    maxH="70vh"
                    boxShadow="md"
                    p={4}
                    mt={5}
                    borderRadius="lg"
                >
                    <TableContainer>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th px={paddingSize}>{t("reference")}</Th>
                                    <Th px={paddingSize}>{t("status")}</Th>
                                    <Th px={paddingSize}>{t("date")}</Th>
                                    <Th px={paddingSize}>{t("progress")}</Th>
                                </Tr>
                            </Thead>
                            {projects
                                .filter((project: any) => {
                                    const searchRegex = new RegExp(searchTerm, 'i');
                                    return (
                                        searchRegex.test(project.reference) || searchRegex.test(project.address)
                                    );
                                })
                                .map((project: any) => (
                                    <ProjectSummaryDetailsComponent key={project._id} idProject={project._id}
                                                                    project={project} handleProjectClick={handleProjectClick}/>
                                ))
                            }
                        </Table>
                    </TableContainer>
                    <ProjectModalComponent selectedProject={selectedProject} handleCloseModal={() => setSelectedProject(null)} callback={() => fetchProjects()}/>
                </Box>
            </Box>
        </Box>
    )
}

export default ProjectsSummaryComponent;