import React from 'react';
import { Avatar, Flex, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface EmployeeCarouselProps {
    employees: string[];
    currentIndex: number;
    prevEmployee: () => void;
    nextEmployee: () => void;
    handleEmployeeSelect: (employeeIndex: number) => void;
    selectedEmployeeName: string;
}

const EmployeeCarousel: React.FC<EmployeeCarouselProps> = ({ employees, currentIndex, prevEmployee, nextEmployee, handleEmployeeSelect, selectedEmployeeName }) => {
    return (
        <Flex justifyContent="center" alignItems="center" mx="auto" my={4}>
            <IconButton icon={<ChevronLeftIcon />} onClick={prevEmployee} aria-label="Previous Employee" />

            <Avatar
                name={employees[currentIndex]}
                size="2xl"
                mx={4}
                onClick={() => handleEmployeeSelect(currentIndex)}
                border={selectedEmployeeName === employees[currentIndex] ? '3px solid black' : 'none'}
            />

            <IconButton icon={<ChevronRightIcon />} onClick={nextEmployee} aria-label="Next Employee" />
        </Flex>
    );
};

export default EmployeeCarousel;
