import React, {useEffect, useState} from "react";
import {
    Accordion,
    Box, Flex,
    useBreakpointValue,
    useColorModeValue
} from "@chakra-ui/react";
import {ProgressBarComponent} from "../projectDetails/ProgressBarComponent";
import {IProject} from "../ProjectDetailsComponent";
import ClientComponent from "../projectDetails/ClientComponent";
import NotesComponent from "../projectDetails/NotesComponent";
import TeamsComponent from "../projectDetails/TeamsComponent";
import TasksComponent from "../projectDetails/TasksComponent";
import DataComponent from "../projectDetails/DataComponent";
import MapComponent from "../projectDetails/MapComponent";
import TagsComponent from "../projectDetails/TagsComponent";
import axios from "axios";
import {getAuthorizedHeader} from "../../../common/auth";
import ProjectMobileCategoriesComponent from "./ProjectMobileCategoriesComponent";
import ReferentComponent from "../projectDetails/ReferentComponent";
import GanttTasks from "../projectDetails/GanttTasks/GanttTasks";

interface ProjectDetailsMobileProps {
    idProject: string;
    project: IProject;
}

function ProjectDetailsMobileComponent(props: ProjectDetailsMobileProps) {
    const [progress, setProgress] = useState(0);
    const [accordionIndex, setAccordionIndex] = useState([4]);

    async function fetchProgress() {
        try {
            const response = await axios.get(`/api/project/progress/` + props.idProject, {
                headers: getAuthorizedHeader(),
            });
            if (response.data && response.data.progress) {
                const progressPercentage = parseFloat(response.data.progress);
                setProgress(progressPercentage);
            }
        } catch (error) {
            console.error('Error fetching project progress', error);
        }
    };
    const handleAccordionChange = (index: any) => {
        setAccordionIndex(index === accordionIndex ? null : index);
    };

    const renderComponent = (component: any) => {
        return accordionIndex !== null && component;
    };

    useEffect(() => {
        fetchProgress();
    }, [props.idProject]);
    return (
        <Box
            padding={2}
            bg={useColorModeValue("gray.200", "gray.700")}
            height={"100%"}
            width={"100%"}
            borderRadius="md"
        >
            <ProgressBarComponent progress={progress}/>
            <Accordion allowMultiple index={accordionIndex} onChange={handleAccordionChange} m={0} p={0}>
                <ProjectMobileCategoriesComponent title={"Client"}
                                                  component={<ClientComponent idClient={props.project.idClient}/>}/>
                <ProjectMobileCategoriesComponent title={"Referent"}
                                                  component={<ReferentComponent idReferent={props.project.idReferent}/>}/>
                <ProjectMobileCategoriesComponent title={"Notes"}
                                                  component={<NotesComponent notes={props.project.notes}/>}/>
                <ProjectMobileCategoriesComponent title={"Teams"}
                                                  component={<TeamsComponent teams={props.project.teams}/>}/>
                <ProjectMobileCategoriesComponent title={"Tasks"}
                                                  component={<TasksComponent
                                                      idProject={props.idProject}
                                                      idSignedQuote={props.project.idSignedQuote}
                                                      idAccommodations={props.project.idAccommodations}
                                                      idCalculationNote={props.project.idCalculationNote}
                                                      idDelivery={props.project.idDelivery}
                                                      idEquipmentRental={props.project.idEquipmentRental}
                                                      idInvoicing={props.project.idInvoicing}
                                                      idPhotos={props.project.idPhotos}
                                                      idOrdersWithDeliveryDate={props.project.idOrdersWithDeliveryDate}
                                                      idPlan={props.project.idPlan}
                                                      idPreVisit={props.project.idPreVisit}
                                                      callback={fetchProgress}/>}/>
                <ProjectMobileCategoriesComponent title={"Data"}
                                                  component={<DataComponent
                                                      categories={props.project.categories}
                                                      address={props.project.address}
                                                      latitude={props.project.latitude}
                                                      longitude={props.project.longitude}
                                                      reference={props.project.reference}
                                                      idDate={props.project.idDate}
                                                      buildingType={props.project.buildingType}/>}/>
                <ProjectMobileCategoriesComponent title={"Map"}
                                                  component={<MapComponent latitude={props.project.latitude}
                                                                           longitude={props.project.longitude}/>}/>
                <ProjectMobileCategoriesComponent title={"Tags"}
                                                  component={<TagsComponent tags={props.project.tags}/>}/>
                <Flex marginTop={5}>
                    <GanttTasks/>
                </Flex>
            </Accordion>
        </Box>
    )
}

export default ProjectDetailsMobileComponent;