import React from 'react';
import NavBar from '../components/navigation/NavBarComponent';
import Profile from '../components/settings/Profile';
import {Flex, Box, useBreakpointValue, Button} from '@chakra-ui/react';
import Language from '../components/settings/Language';
import DarkMode from '../components/settings/DarkMode';
import {logout} from '../common/auth';
import {FaDoorOpen} from "react-icons/fa";
import {useNavigate} from 'react-router-dom';
import ColorPalet from "../components/settings/ColorPalet";

function SettingsScreen() {
  const isSmallScreen = useBreakpointValue({base: true, md: false});
  const navigate = useNavigate();

  return (
    <Box width={"100%"}>
      <NavBar/>
      <Flex flexDirection={isSmallScreen ? "column" : "row"} width={"100%"} height={"100%"} padding={isSmallScreen ? 3 : 10}>
        <Profile/>
        <Flex flexDirection="column"
              width={isSmallScreen ? "100%" : "50%"}
        >
          <Language/>
          <DarkMode/>
        </Flex>
      </Flex>
    </Box>
  );
}

export default SettingsScreen;
