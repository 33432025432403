import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Box,
    Button,
    Divider,
    Flex,
    Text,
    HStack,
    IconButton,
    useColorModeValue,
} from '@chakra-ui/react';
import {AddIcon, ArrowBackIcon, CloseIcon, DeleteIcon} from "@chakra-ui/icons";

interface ManageTeamsAccordionProps {
    teams: { name: string, members: string[] }[];
    employees: string[];
    handleDeleteTeam: (teamIndex: number) => void;
    handleToggleMember: (teamIndex: number, employee: string) => void;
    onBack: () => void;
    onCreateTeam: () => void;
}

const ManageTeamsAccordion: React.FC<ManageTeamsAccordionProps> = ({
                                                                       teams,
                                                                       employees,
                                                                       handleDeleteTeam,
                                                                       handleToggleMember,
                                                                       onBack,
                                                                       onCreateTeam
                                                                   }) => {
    const borderColor = useColorModeValue('gray.300', 'gray.600');
    const backgroundPanel = useColorModeValue('gray.50', 'gray.700');
    const hoverColor = useColorModeValue('gray.100', 'gray.600');
    const headingColor = useColorModeValue('gray.800', 'gray.100');
    const scrollbarColor = useColorModeValue('gray.400', 'gray.500');

    return (
        <Box
            width="100%"
            maxHeight="400px"
            overflowY="auto"
            mx="auto"
            border="1px solid"
            borderColor={borderColor}
            borderRadius="lg"
            p={4}
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: scrollbarColor,
                    borderRadius: '8px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: '#A0AEC0',
                },
                '&::-webkit-scrollbar-track': {
                    background: 'transparent',
                },
            }}
        >
            <HStack justify="space-between" mb={6} spacing={4} align="center">
                <IconButton
                    aria-label="Retour en arrière"
                    icon={<ArrowBackIcon />}
                    onClick={onBack}
                    size="md"
                    variant="outline"
                    borderColor="gray.500"
                    color="gray.500"
                    _hover={{ bg: 'gray.700', color: 'white', borderColor: 'white' }}
                    _focus={{ borderColor: 'white' }}
                    borderRadius="full"
                    p={2}
                />
                <IconButton
                    aria-label="Créer une équipe"
                    icon={<AddIcon />}
                    onClick={onCreateTeam}
                    size="md"
                    variant="outline"
                    borderColor="gray.500"
                    color="gray.500"
                    _hover={{ bg: 'gray.700', color: 'white', borderColor: 'white' }}
                    _focus={{ borderColor: 'white' }}
                    borderRadius="full"
                    p={2}
                />
            </HStack>
            <Accordion allowToggle width="100%">
                {teams.map((team, index) => (
                    <AccordionItem
                        key={index}
                        border="none"
                        mt={4}
                        width="100%"
                        bg={backgroundPanel}
                        borderRadius="md"
                        transition="all 0.3s ease"
                    >
                        <AccordionButton
                            _expanded={{ bg: hoverColor, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}
                            _hover={{ bg: 'gray.600' }}
                            borderRadius="md"
                            transition="background 0.3s ease"
                            py={4}
                        >
                            <Box flex="1" textAlign="left" fontWeight="bold" fontSize="lg" color={headingColor}>
                                {team.name}
                            </Box>
                            <AccordionIcon />
                            <IconButton
                                aria-label="Supprimer"
                                icon={<DeleteIcon />}
                                size="xs"
                                colorScheme="red"
                                ml={4}
                                _hover={{ bg: 'red.500' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteTeam(index);
                                }}
                            />
                        </AccordionButton>
                        <AccordionPanel
                            pb={4}
                            bg={backgroundPanel}
                            width="100%"
                            borderRadius="md"
                            transition="background 0.3s ease"
                        >
                            {employees.map((employee, employeeIndex) => {
                                const isMember = team.members.includes(employee);

                                return (
                                    <Box key={employeeIndex} mb={2}>
                                        <Flex
                                            align="center"
                                            justify="space-between"
                                            py={3}
                                            px={4}
                                            bg={isMember ? 'gray.50' : 'white'}
                                            borderRadius="full"
                                            _hover={{
                                                bg: isMember ? 'red.50' : 'green.50',
                                                transform: 'scale(1.02)',
                                            }}
                                            transition="all 0.3s ease"
                                        >
                                            <Text
                                                fontSize="md"
                                                fontWeight={isMember ? 'semibold' : 'normal'}
                                                color={isMember ? 'red.600' : 'gray.700'}
                                                transition="color 0.3s ease"
                                            >
                                                {employee}
                                            </Text>
                                            <IconButton
                                                icon={isMember ? <CloseIcon /> : <AddIcon />}
                                                aria-label={isMember ? "Retirer de la liste" : "Ajouter à la liste"}
                                                size="sm"
                                                colorScheme={isMember ? 'red' : 'green'}  // Gris pour le retrait, vert pour l'ajout
                                                _hover={{
                                                    bg: isMember ? 'red.200' : 'green.200',  // Couleurs plus douces pour le survol
                                                    transform: 'scale(1.05)',  // Effet de zoom plus subtil
                                                }}
                                                transition="all 0.2s ease"
                                                borderRadius="full"
                                                onClick={() => handleToggleMember(index, employee)}  // Fonction de gestion de l'action
                                            />

                                        </Flex>
                                        {employeeIndex < employees.length - 1 && (
                                            <Divider
                                                borderColor="gray.200"
                                                opacity={0.6}
                                                my={2}
                                            />
                                        )}
                                    </Box>
                                );
                            })}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Box>
    );
};

export default ManageTeamsAccordion;
