import React from "react";
import {Box, Text, useColorModeValue} from "@chakra-ui/react";
import CopyButtonComponent from "../../common/CopyButtonComponent";

interface DataDetailsProps {
    header: string;
    detail: any;
}

function DataDetails(props: DataDetailsProps) {
    return (
        <Box marginTop={3}
             marginBottom={4}
             bg={useColorModeValue('white', 'gray.600')}
        >
            <Text fontSize='sm' color={useColorModeValue('gray.300', 'gray.400')}>
                <strong>{props.header}</strong>
            </Text>
            <Text position="relative"
                  transition="transform 0.3s, font-size 0.3s"
                  _hover={{
                      transform: 'scale(1.1)',
                  }}
                  _after={{
                      transition: 'width 0.420s ease-in-out',
                  }}
                  fontSize='sm'>
                {props.detail}
                <Box position="absolute" right="0" top="50%" transform="translateY(-50%)">
                    <CopyButtonComponent detail={props.detail}/>
                </Box>
            </Text>
        </Box>
    );
}

export default DataDetails;
