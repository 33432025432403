import React, { useState, useEffect } from 'react';
import { Calendar, Tooltip } from 'antd';
import { Box, Text, Divider, useBreakpointValue, useColorModeValue, VStack, Switch, Stack } from '@chakra-ui/react';
import { FaFilter } from 'react-icons/fa';
import type { CalendarProps } from 'antd';
import type { Dayjs } from 'dayjs';
import './CalendarComponent.css';
import { getAuthorizedHeader } from "../../common/auth";
import axios from "axios";

export interface IAppointment {
    _id: string;
    name: string;
    address: string;
    type: string;
    note: string;
    participants: string[];
    date: string;
    timeStart: string;
    timeEnd: string;
    email?: string;
    phone?: string;
}

interface CalendarComponentProps {
    appointments: IAppointment[];
}

function CalendarComponent(props: CalendarComponentProps) {
    const [participantData, setParticipantData] = useState<{ [appointmentId: string]: string[] }>({});
    const isSmallScreen = useBreakpointValue({ base: true, md: false });

    const eventTypes = Array.from(new Set(props.appointments.map((appointment) => appointment.type)));

    const fetchParticipants = async (appointmentId: string, participants: string[]) => {
        try {
            const response = await axios.get('/api/participants', {
                headers: getAuthorizedHeader(),
                params: { participants }
            });
            setParticipantData(prevState => ({
                ...prevState,
                [appointmentId]: response.data.participants || [],
            }));
        } catch (error) {
            console.error('Error fetching participants:', error);
        }
    };


    useEffect(() => {
        props.appointments.forEach(appointment => {
            fetchParticipants(appointment._id, appointment.participants);
        });
    }, [props.appointments]);

    const getListData = (value: Dayjs, selectedTypes: string[]) => {
        const currentDate = value.format('DD/MM/YYYY');
        return props.appointments
            .filter(appointment => appointment.date === currentDate && selectedTypes.includes(appointment.type))
            .map(appointment => ({ type: appointment.type, appointment }));
    };

    const getTagColors = (type: string) => {
        switch (type) {
            case "project": return { borderColor: "#3182CE", backgroundColor: "rgba(49, 130, 206, 0.1)" };
            case "banque": return { borderColor: "#D69E2E", backgroundColor: "rgba(214, 158, 46, 0.1)" };
            case "reunion": return { borderColor: "#38A169", backgroundColor: "rgba(56, 161, 105, 0.1)" };
            case "rendez-vous": return { borderColor: "#9F7AEA", backgroundColor: "rgba(159, 122, 234, 0.1)" };
            default: return { borderColor: "#CBD5E0", backgroundColor: "rgba(203, 213, 224, 0.4)" };
        }
    };

    const [selectedTypes, setSelectedTypes] = useState(eventTypes);

    const onSwitchChange = (type: string, checked: boolean) => {
        setSelectedTypes(prev => checked ? [...prev, type] : prev.filter(t => t !== type));
    };



    const dateCellRender = (value: Dayjs) => {
        const listData = getListData(value, selectedTypes);
        const boxPadding = useBreakpointValue({ base: 1, md: 2 });
        const fontSize = useBreakpointValue({ base: "xs", md: "sm" });
        const spacing = useBreakpointValue({ base: 0.5, md: 1 });

        return (
            <VStack spacing={spacing} align="start" w="full">
                {listData.map((item) => {
                    const { borderColor, backgroundColor } = getTagColors(item.type);
                    const participants = participantData[item.appointment._id] || ['Loading...'];

                    return (
                        <Box
                            key={item.appointment._id}
                            p={boxPadding}
                            rounded="md"
                            w="full"
                            bg={!isSmallScreen ? backgroundColor : 'transparent'}
                            border={!isSmallScreen ? `1px solid ${borderColor}` : 'none'}
                            display="flex"
                            alignItems="center"
                            justifyContent={isSmallScreen ? "center" : "space-between"}
                        >
                            <Tooltip
                                placement="top"
                                color="gray.700"
                                overlayInnerStyle={{
                                    backgroundColor: '#2D3748',
                                    padding: '8px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
                                }}
                                title={(
                                    <div style={{ color: 'white', fontSize: '12px', lineHeight: '1.4' }}>
                                        <Text fontSize="md" fontWeight="bold" mb={2}>
                                            {item.appointment.name}
                                        </Text>
                                        <VStack align="start" spacing={1}>
                                            <Box display="flex" alignItems="center">
                                                <Text fontWeight="bold" mr={1}>Participants:</Text>
                                                <Text>{participants.join(', ')}</Text>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Text fontWeight="bold" mr={1}>Note:</Text>
                                                <Text>{item.appointment.note}</Text>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Text fontWeight="bold" mr={1}>Time:</Text>
                                                <Text>{item.appointment.timeStart} - {item.appointment.timeEnd}</Text>
                                            </Box>
                                        </VStack>
                                    </div>
                                )}
                            >
                                <VStack align="center" spacing={0} w="full">
                                    <Text fontSize={fontSize} fontWeight="bold" color={borderColor} noOfLines={1}>
                                        {item.appointment.name}
                                    </Text>
                                </VStack>
                            </Tooltip>
                        </Box>
                    );
                })}
            </VStack>
        );
    };
    const cellRender: CalendarProps<Dayjs>['cellRender'] = (current: any, info: any) => {
        if (info.type === 'date') return dateCellRender(current);
        return info.originNode;
    };

    return (
        <Box
            bg={useColorModeValue("gray.200", "gray.700")}
            marginLeft={isSmallScreen ? 3 : 10}
            my={isSmallScreen ? 3 : 5}
            marginRight={isSmallScreen ? 3 : 0}
            rounded="md"
            boxShadow="md"
            padding={isSmallScreen ? 2 : 5}
            display={"flex"}
            flexDirection={isSmallScreen ? 'column' : 'row'}>
            <Box minWidth={"15%"} bg="rgba(0, 0, 0, 0.3)" p={4} rounded="md" mr={isSmallScreen ? 0 : 2} mb={isSmallScreen ? 2 : 0}>
                <Box display="flex" alignItems="center" mb={4}>
                    <FaFilter size={20} color="#3182CE" />
                </Box>
                <Box overflowX={isSmallScreen ? "auto" : "visible"}>
                    <Stack direction={isSmallScreen ? 'row' : 'column'} spacing={3} w="full" minW="0">
                        {eventTypes.map((type) => {
                            const { borderColor } = getTagColors(type);
                            const colorScheme = (() => {
                                switch (type) {
                                    case "project": return "blue";
                                    case "banque": return "yellow";
                                    case "reunion": return "green";
                                    case "rendez-vous": return "purple";
                                    default: return "gray";
                                }
                            })();
                            return (
                                <Box key={type} display="flex" alignItems="center" flexShrink={0} mb={isSmallScreen ? 2 : 0}>
                                    <Switch
                                        isChecked={selectedTypes.includes(type)}
                                        onChange={(e) => onSwitchChange(type, e.target.checked)}
                                        mr={2}
                                        colorScheme={colorScheme}
                                    />
                                    <Text fontWeight="bold" color={borderColor}>
                                        {type.charAt(0).toUpperCase() + type.slice(1)}
                                    </Text>
                                </Box>
                            );
                        })}
                    </Stack>
                </Box>
            </Box>
            <Divider orientation={isSmallScreen ? "horizontal" : "vertical"} height={isSmallScreen ? "1px" : "100%"} />
            <Box
                rounded="md"
                boxShadow="md"
                className="custom-calendar"
                style={{ flexGrow: 1 }}>
                <Calendar style={{ }} cellRender={cellRender} />
            </Box>
        </Box>
    );
}

export default CalendarComponent;
