import React from "react";
import {useTranslation} from "react-i18next";
import {Box, Flex, Tag, TagLabel, Text, useBreakpointValue, useColorModeValue, Image} from "@chakra-ui/react";
import {getStatusColor} from "../../common/status";
import SupComponent from "../common/SupComponent";
import {getAuthorizedHeader} from "../../common/auth";
import axios from "axios";

interface ProjectPreviewProps {
    project: any;
    handleProjectClick: (project: any) => void;
    callback: () => void;
}

function ProjectPreviewComponent({project, handleProjectClick, callback}: ProjectPreviewProps) {
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const {t} = useTranslation();

    async function deleteProject(projectId: string) {
        try {
            const response = await axios.delete(`/api/project/${projectId}`, {
                headers: getAuthorizedHeader(),
            });
            console.log('Project marked for deletion:', response.data.message);
        } catch (error) {
            console.error('Failed to delete project:', error.response ? error.response.data : error.message);
        }
    }

    const getProjectLogo = () => {
        if (project.reference.toLowerCase().includes("arkolia")) {
            return "https://arkolia.com/wp-content/uploads/2023/11/favicon_ARKOLIA-512x512px.png";
        } else if (project.reference.toLowerCase().includes("volta")) {
            return "https://www.groupe-volta.com/wp-content/uploads/2021/05/Picto-volta-bleu.svg";
        }
        return "https://cdn-icons-png.flaticon.com/512/430/430422.png";
    };

    const projectLogo = getProjectLogo();

    return (
        <Box
            key={project._id}
            p={{base: 2, md: 3}}
            borderWidth="1px"
            borderColor={getStatusColor(project.status)}
            rounded="md"
            shadow="md"
            bg={useColorModeValue('white', 'gray.700')}
            mt={2}
            onClick={() => handleProjectClick(project)}
            _hover={{transform: 'scale(1.02)'}}
            transition="transform 0.2s"
            position="relative"
        >
            <Flex
                direction={isSmallScreen ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems="center"
                textAlign="center"
            >
                <Flex alignItems="center" flex="1" justifyContent={isSmallScreen ? 'center' : 'flex-start'}>
                    {projectLogo && (
                        <Image
                            src={projectLogo}
                            alt="Project logo"
                            boxSize="40px"
                            mr={2}
                        />
                    )}
                    <Text fontWeight="bold" color={useColorModeValue('black', 'white')}>
                        {project.reference}
                    </Text>
                </Flex>
                <Text flex="2" fontWeight="bold" color={useColorModeValue('black', 'white')}>
                    {project.address}
                </Text>
                <Text flex="1" color="gray.500">
                    {project.date.begin} - {project.date.end}
                </Text>
                <Box flex="1">
                    <Tag size="lg" colorScheme={getStatusColor(project.status)} borderRadius="full">
                        <TagLabel>{t(project.status)}</TagLabel>
                    </Tag>
                </Box>

                {!isSmallScreen && (
                    <Box flex="1" onClick={(e) => e.stopPropagation()}>
                        <SupComponent
                            onClickDelete={() => deleteProject(project._id)}
                            callback={async () => callback()}
                        />
                    </Box>
                )}
            </Flex>

            {isSmallScreen && (
                <Box
                    position="absolute"
                    bottom="10px"
                    right="10px"
                    zIndex={1}
                >
                    <SupComponent
                        onClickDelete={() => deleteProject(project._id)}
                        callback={async () => callback()}
                    />
                </Box>
            )}
        </Box>

    );
}

export default ProjectPreviewComponent;
