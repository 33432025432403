import React, { useState } from 'react';
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    IconButton,
    Text,
    Badge,
    VStack,
    HStack,
    Collapse,
    Icon,
    Button,
    useColorModeValue,
    useDisclosure
} from '@chakra-ui/react';
import { BellIcon, CalendarIcon, WarningIcon, CheckIcon, AddIcon, ChevronDownIcon } from '@chakra-ui/icons';

type NotificationType = 'rendezvous' | 'retard' | 'tache_terminee' | 'nouveau_projet';

interface Notification {
    id: number;
    type: NotificationType;
    isRead: boolean;
    requester?: string;
    date?: string;
    location?: string;
    subject?: string;
    projectName?: string;
    delayDays?: number;
    blockingTask?: string;
    completedTask?: string;
    endDate?: string;
    timestamp: string;
}

    // Fonction pour gérer l'acceptation de la notification
    const handleAccept = (id: number) => {
        console.log(`Notification ${id} acceptée.`);
        // Logique pour gérer l'acceptation, comme marquer la notification comme lue ou lancer une action
    };

    // Fonction pour gérer le refus de la notification
    const handleDecline = (id: number) => {
        console.log(`Notification ${id} refusée.`);
        // Logique pour gérer le refus, comme marquer la notification comme lue ou effectuer une autre action
    };


const getNotificationDetails = (notification: Notification) => {
    switch (notification.type) {
        case 'rendezvous':
            return (
                <Box>
                    <Text>
                        Demande de RDV par <strong>{notification.requester}</strong> le <strong>{notification.date}</strong> à <strong>{notification.location}</strong> pour "<strong>{notification.subject}</strong>".
                    </Text>
                    <HStack mt={4} spacing={4}>
                        <Button
                            onClick={() => handleAccept(notification.id)}
                            bg="green.400"
                            color="white"
                            _hover={{ bg: "green.500", transform: "scale(1.05)" }}
                            _active={{ bg: "green.600" }}
                            borderRadius="full"
                            boxShadow="md"
                            px={6}
                            py={2}
                            fontWeight="semibold"
                            transition="all 0.2s ease"
                        >
                            Accepter
                        </Button>
                        <Button
                            onClick={() => handleDecline(notification.id)}
                            bg="red.400"
                            color="white"
                            _hover={{ bg: "red.500", transform: "scale(1.05)" }}
                            _active={{ bg: "red.600" }}
                            borderRadius="full"
                            boxShadow="md"
                            px={6}
                            py={2}
                            fontWeight="semibold"
                            transition="all 0.2s ease"
                        >
                            Refuser
                        </Button>
                    </HStack>
                </Box>
            );
        case 'retard':
            return (
                <Text>
                    Le projet <strong>{notification.projectName}</strong> est en retard de <strong>{notification.delayDays}</strong> jours à cause de "<strong>{notification.blockingTask}</strong>".
                </Text>
            );
        case 'tache_terminee':
            return (
                <Text>
                    La tâche "<strong>{notification.completedTask}</strong>" du projet <strong>{notification.projectName}</strong> a été terminée.
                </Text>
            );
        case 'nouveau_projet':
            return (
                <Text>
                    Nouveau projet "<strong>{notification.projectName}</strong>" disponible jusqu'au <strong>{notification.endDate}</strong>.
                </Text>
            );
        default:
            return 'Détails non disponibles.';
    }
};

const getNotificationPreview = (notification: Notification) => {
    switch (notification.type) {
        case 'rendezvous':
            return <Text fontSize="sm">Demande de RDV par {notification.requester}</Text>;
        case 'retard':
            return <Text fontSize="sm">Projet {notification.projectName} en retard</Text>;
        case 'tache_terminee':
            return <Text fontSize="sm">Tâche {notification.completedTask} terminée</Text>;
        case 'nouveau_projet':
            return <Text fontSize="sm">Nouveau projet: {notification.projectName}</Text>;
        default:
            return <Text fontSize="sm">Détails indisponibles</Text>;
    }
};


const NotificationDrawer = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [expandedId, setExpandedId] = useState<number | null>(null);
    const [notifications, setNotifications] = useState<Notification[]>([
        { id: 1, type: 'rendezvous', isRead: false, requester: 'Alice Dupont', date: '28-03-2024', location: 'Bureau Paris', subject: 'Réunion de projet', timestamp: new Date().toISOString() },
        { id: 2, type: 'retard', isRead: false, projectName: 'Projet Alpha', delayDays: 3, blockingTask: 'Validation du design', timestamp: new Date(Date.now() - 3600 * 1000).toISOString() },
        { id: 3, type: 'tache_terminee', isRead: true, projectName: 'Projet Beta', completedTask: 'Développement de la fonctionnalité X', timestamp: new Date(Date.now() - 86400 * 1000).toISOString() },
        { id: 4, type: 'nouveau_projet', isRead: true, projectName: 'Projet Snoup', endDate: '30-06-2024', timestamp: new Date(Date.now() - 3 * 86400 * 1000).toISOString() },
    ]);

    const bgColor = useColorModeValue("white", "gray.700");
    const cardBg = useColorModeValue("gray.100", "gray.800");
    const iconColors: Record<NotificationType, string> = {
        rendezvous: "blue.500",
        retard: "red.500",
        tache_terminee: "green.500",
        nouveau_projet: "purple.500",
    };

    const getIconByType = (type: NotificationType) => {
        switch (type) {
            case 'rendezvous': return <CalendarIcon color={iconColors.rendezvous} />;
            case 'retard': return <WarningIcon color={iconColors.retard} />;
            case 'tache_terminee': return <CheckIcon color={iconColors.tache_terminee} />;
            case 'nouveau_projet': return <AddIcon color={iconColors.nouveau_projet} />;
            default: return null;
        }
    };



    const getUnreadCount = () => notifications.filter(notification => !notification.isRead).length;

    const formatDate = (timestamp: string) => {
        const now = new Date();
        const date = new Date(timestamp);
        const isToday = now.toDateString() === date.toDateString();

        if (isToday && now.getTime() - date.getTime() <= 86400000) {
            return date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
        } else {
            return date.toLocaleDateString('fr-FR', { weekday: 'short' }).slice(0, 3);
        }
    };

    const sortedNotifications = [...notifications].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

    const markAllAsRead = () => {
        setNotifications(notifications.map(notification => ({ ...notification, isRead: true })));
    };

    return (
        <Box>
            <Box position="relative">
                <IconButton
                    onClick={() => {
                        onOpen();
                        markAllAsRead();
                    }}
                    icon={<BellIcon />}
                    size="md"
                    aria-label="Notifications"
                    className="buttonStyle"
                    boxShadow="md"
                    _hover={{ transform: 'scale(1.1)', bg: bgColor }}
                    p={0}
                    m={0}
                />
                {getUnreadCount() > 0 && (
                    <Badge
                        position="absolute"
                        top="0"
                        right="0"
                        transform="translate(50%, -50%)"
                        colorScheme="red"
                        borderRadius="full"
                        px={2}
                        py={0.5}
                        fontSize="xs"
                    >
                        {getUnreadCount()}
                    </Badge>
                )}
            </Box>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
                <DrawerOverlay bg="none" backdropFilter="blur(2px)" />
                <DrawerContent bg={cardBg}>
                    <DrawerCloseButton />
                    <DrawerHeader>Notifications</DrawerHeader>
                    <DrawerBody>
                        <VStack align="stretch" spacing={4}>
                            {sortedNotifications.map(notification => (
                                <Box
                                    key={notification.id}
                                    bg={notification.isRead ? cardBg : useColorModeValue("blue.50", "blue.900")}
                                    borderRadius="lg"
                                    p={4}
                                    boxShadow={expandedId === notification.id ? "lg" : "md"}
                                    borderWidth={expandedId === notification.id ? "2px" : "1px"}
                                    borderColor={expandedId === notification.id ? iconColors[notification.type] : "transparent"}
                                    _hover={{ boxShadow: "lg" }}
                                    position="relative"
                                >
                                    <Text
                                        position="absolute"
                                        top="4px"
                                        right="8px"
                                        fontSize="xs"
                                        color="gray.500"
                                    >
                                        {formatDate(notification.timestamp)}
                                    </Text>
                                    <HStack spacing={3} onClick={() => {
                                        setExpandedId(expandedId === notification.id ? null : notification.id);
                                        if (!notification.isRead) {
                                            setNotifications(notifications.map(n =>
                                                n.id === notification.id ? { ...n, isRead: true } : n
                                            ));
                                        }
                                    }} cursor="pointer">
                                        {getIconByType(notification.type)}
                                        {getNotificationPreview(notification)}
                                    </HStack>
                                    <Collapse in={expandedId === notification.id}>
                                        <Box mt={2} pl={5} ml={1.5} borderLeft="2px solid" borderColor={iconColors[notification.type]}>
                                          {getNotificationDetails(notification)}
                                        </Box>
                                    </Collapse>
                                </Box>
                            ))}
                        </VStack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    );
};

export default NotificationDrawer;
