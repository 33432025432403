import React, {useEffect, useState} from "react";
import {Box, Flex, Text, useBreakpointValue, useColorModeValue} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck, faSuitcase, faTasks} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {getAuthorizedHeader} from "../common/auth";

function ManagerBarComponent() {
    const [projectNumber, setProjectNumber] = useState(0);
    const [taskNumber, setTaskNumber] = useState(0);
    const [appointmentsNumber, setAppointmentsNumber] = useState(0);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const isSmallScreen = useBreakpointValue({base: true, md: false});

    function fetchTaskCount() {
        axios.get('/api/tasks/me/count', {
            headers: getAuthorizedHeader(),
        })
            .then(response => {
                setTaskNumber(response.data.taskCount);
            })
            .catch(error => {
                console.error('Error fetching tasks:', error);
            });
    }

    function fetchAppointmentCount() {
        axios.get('/api/rdv', {
            headers: getAuthorizedHeader(),
        })
            .then(response => {
                // Set the appointment number based on the response length
                setAppointmentsNumber(response.data.length);
            })
            .catch(error => {
                console.error('Error fetching appointments:', error);
            });
    }

    useEffect(() => {
        const fetchProjectCount = async () => {
            try {
                const response = await axios.get('/api/projects/in-progress/count', {headers: getAuthorizedHeader()});
                setProjectNumber(response.data.inProgressCount);
            } catch (error) {
                console.error("Error fetching in-progress project count:", error);
            }
        };


        fetchProjectCount();
        fetchTaskCount();
        fetchAppointmentCount();
    }, []);

    return (
        <Flex
            bg={useColorModeValue("gray.200", "gray.700")}
            rounded="md"
            boxShadow="md"
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
            textAlign="center"
            height={'25%'}
            width={isSmallScreen ? 'calc("100% - 20px")' : "97%"}
            padding={isSmallScreen ? 3 : 10}
            mx={isSmallScreen ? 3 : 10}
            mt={isSmallScreen ? 3 : 10}
            mb={isSmallScreen ? 3 : 5}
        >
            <Box onClick={() => navigate('/projects')}>
                <FontAwesomeIcon icon={faSuitcase} size={isSmallScreen ? "2x" : '3x'} color="#6495ED"/>
                <Text fontSize={isSmallScreen ? "2xl" : '4xl'} fontWeight="bold" mt={2} color={"blue.500"}>
                    {projectNumber}
                </Text>
                <Text fontSize={isSmallScreen ? "md" : 'xl'}>{t('projects')}</Text>
            </Box>
            <Box onClick={() => navigate('/tasks')}>
                <FontAwesomeIcon icon={faTasks} size={isSmallScreen ? "2x" : '3x'} color="#3CB371"/>
                <Text fontSize={isSmallScreen ? "2xl" : '4xl'} fontWeight="bold" mt={2} color="green.500">
                    {taskNumber}
                </Text>
                <Text fontSize={isSmallScreen ? "md" : 'xl'}>{t('tasks')}</Text>
            </Box>
            <Box onClick={() => navigate('/calendar')}>
                <FontAwesomeIcon icon={faCalendarCheck} size={isSmallScreen ? "2x" : '3x'} color="#F08080"/>
                <Text fontSize={isSmallScreen ? "2xl" : '4xl'} fontWeight="bold" mt={2} color="red.500">
                    {appointmentsNumber}
                </Text>
                <Text fontSize={isSmallScreen ? "md" : 'xl'}>{t('calendar')}</Text>
            </Box>
        </Flex>
    );
}

export default ManagerBarComponent;
