import React, {useEffect, useState} from "react";
import {
    Box,
    Spinner,
    Text,
    Flex,
    Divider,
    useColorModeValue, useBreakpointValue,
} from "@chakra-ui/react";
import {getStatusColor} from "../../../common/status";
import axios from "axios";
import {getAuthorizedHeader} from "../../../common/auth";
import {pdfjs} from "react-pdf";
import PrevisitFormComponent from "./PrevisitFormComponent";
import TaskValidatorComponent from "./tasks/TaskValidatorComponent";
import TaskCommonDataComponent from "./tasks/TaskCommonDataComponent";
import {useTranslation} from "react-i18next";
import PdfPreviewComponent from "./tasks/PdfPreviewComponent";
import ImagePreviewComponent from "./tasks/ImagePreviewComponent";
import DwgPreviewComponent from "./tasks/DwgPreviewComponent";
import TaskModificatorComponent from "./tasks/TaskModificatorComponent";
import UploadButtonTasksComponent from "./tasks/UploadButtonTasksComponent";
import ShowButtonComponent from "../../common/ShowButtonComponent";
import ExcelPreviewComponent from "./tasks/ExcelPreviewComponent";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface TaskDetailsProps {
    idProject: string;
    idTask: string;
    task: string;
    status: string;
    callback: () => void;
    data: boolean;
}

interface TaskResponse {
    preVisitFormId ?: string;
    pdfs?: string[];
    images?: string[];
    dwgs?: string[];
    excels?: string[];
}

interface FileDetail {
    name: string;
    url?: string;
    task: string;
    taskId: string;
    fileId: string;
    callback: () => Promise<void>
}

function TaskDetailsComponent(props: TaskDetailsProps) {
    const {t} = useTranslation();
    const [task, setTask] = useState(null as TaskResponse);
    const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
    const [loadingImage, setLoadingImage] = useState<boolean>(false);
    const [loadingDwg, setLoadingDwg] = useState<boolean>(false);
    const [loadingExcel, setLoadingExcel] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(props.data);
    const [pdfData, setPdfData] = useState<Map<string, FileDetail>>(new Map());
    const [imageData, setImageData] = useState<Map<string, FileDetail>>(new Map());
    const [dwgData, setDwgData] = useState<Map<string, FileDetail>>(new Map());
    const [excelData, setExcelData] = useState<Map<string, FileDetail>>(new Map());
    const [isMember, setIsMember] = useState<boolean>(false);
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const [preVisitFormId, setPreVisitFormId] = useState(null);

    async function fetchTask() {
        setTask(null);
        setPdfData(new Map());
        setImageData(new Map());
        setDwgData(new Map());
        setExcelData(new Map());
        try {
            const response = await axios.get('/api/' + props.task + '/' + props.idTask, {
                headers: getAuthorizedHeader(),
            });
            setTask(response.data);

            if (response.data.preVisitFormId)
                setPreVisitFormId(response.data.preVisitFormId);
            if (show) {
                if (response.data.pdfs) response.data.pdfs.forEach(fetchPdf);
                if (response.data.images) response.data.images.forEach(fetchImage);
                if (response.data.dwgs) response.data.dwgs.forEach(fetchDwg);
                if (response.data.excels) response.data.excels.forEach(fetchExcel);
            }
        } catch (error) {
            console.error('Error fetching Task:', error);
        }
    }

    async function fetchPdf(pdfId: string) {
        setLoadingPdf(true);
        try {
            const pdf = await axios.get('/api/pdf/' + pdfId, {
                headers: getAuthorizedHeader(),
                responseType: 'blob',
            });
            const pdfName = await axios.get('/api/pdf/name/' + pdfId, {
                headers: getAuthorizedHeader(),
            });
            const pdfUrl = URL.createObjectURL(pdf.data);
            setPdfData((prevMap) => new Map(prevMap.set(pdfId, {
                name: pdfName.data,
                url: pdfUrl,
                task: props.task,
                taskId: props.idTask,
                fileId: pdfId,
                callback: fetchTask
            })));
            setLoadingPdf(false);
        } catch (error) {
            console.error('Error fetching PDF:', error);
            setLoadingPdf(false);
        }
    }

    async function fetchImage(imageId: string) {
        setLoadingImage(true);
        try {
            const response = await axios.get(`/api/image/${imageId}`, {
                headers: getAuthorizedHeader(),
                responseType: 'blob',
            });
            const imageName = await axios.get('/api/image/name/' + imageId, {
                headers: getAuthorizedHeader(),
            });
            const imageUrl = URL.createObjectURL(response.data);
            setImageData(prevMap => new Map(prevMap.set(imageId, {
                name: imageName.data,
                url: imageUrl,
                task: props.task,
                taskId: props.idTask,
                fileId: imageId,
                callback: fetchTask

            })));
            setLoadingImage(false);
        } catch (error) {
            console.error('Error fetching Image:', error);
            setLoadingImage(false);
        }
    }

    async function fetchDwg(dwgId: string) {
        setLoadingDwg(true);
        try {
            const response = await axios.get(`/api/dwg/${dwgId}`, {
                headers: getAuthorizedHeader(),
                responseType: 'blob',
            });
            const dwgName = await axios.get('/api/dwg/name/' + dwgId, {
                headers: getAuthorizedHeader(),
            });
            const dwgUrl = URL.createObjectURL(response.data);
            setDwgData(prevMap => new Map(prevMap.set(dwgId, {
                name: dwgName.data,
                url: dwgUrl,
                task: props.task,
                taskId: props.idTask,
                fileId: dwgId,
                callback: fetchTask

            })));
            setLoadingDwg(false);
        } catch (error) {
            console.error('Error fetching DWG:', error);
            setLoadingDwg(false);
        }
    }

    async function fetchExcel(excelId: string) {
        setLoadingExcel(true);
        try {
            const response = await axios.get(`/api/excel/${excelId}`, {
                headers: getAuthorizedHeader(),
                responseType: 'blob',
            });
            const excelName = await axios.get('/api/excel/name/' + excelId, {
                headers: getAuthorizedHeader(),
            });
            const excelUrl = URL.createObjectURL(response.data);
            setExcelData(prevMap => new Map(prevMap.set(excelId, {
                name: excelName.data,
                url: excelUrl,
                task: props.task,
                taskId: props.idTask,
                fileId: excelId,
                callback: fetchTask
            })));
            setLoadingExcel(false);
        } catch (error) {
            console.error('Error fetching Excel:', error);
            setLoadingExcel(false);
        }
    }

    async function handleShowClick() {
        setShow(!show);
        props.callback();
    }

    async function checkTeamMembership(taskName: string) {
        try {
            const response = await axios.get(`/api/teams/${taskName}/membership`, {
                headers: getAuthorizedHeader(),
            });
            setIsMember(response.data.isMember);
        } catch (error) {
            console.error("Error checking team membership:", error);
        }
    }

    useEffect(() => {
        checkTeamMembership(props.task);
        fetchTask();
    }, [props]);

    return (
        <Box
            bg={useColorModeValue('white', 'gray.600')}
            borderWidth="1px"
            border-radius={4}
            borderColor={getStatusColor(props.status)}
            padding={isSmallScreen ? 3 : 5}
            rounded="md"
            boxShadow="md">
            <Text fontSize='2xl' color="gray.300">{t(`${props.task}`)}</Text>
            <Divider/>
            <TaskCommonDataComponent daysLefts={2} beginDate={'11/02/2024'} endDate={'20/04/2024'}
                                     status={props.status}/>
            {props.task === "preVisit" && <PrevisitFormComponent preVisitId={props.idTask} preVisitFormId={preVisitFormId} callback={props.callback}/>}

            {loadingPdf && show ? <Spinner/> : show ? (
                Array.from(pdfData.entries()).map(([pdfId, pdfDetails]) => (
                    <PdfPreviewComponent key={pdfId} url={pdfDetails.url} fileId={pdfDetails.fileId}
                                         name={pdfDetails.name} task={pdfDetails.task} taskId={pdfDetails.taskId}
                                         callback={fetchTask}/>
                ))
            ) : null}
            {loadingImage && show ? <Spinner/> : show ? (
                <Flex wrap="wrap" justifyContent="center" gap="20px">
                    {Array.from(imageData.entries()).map(([imageId, imageDetails]) => (
                        <ImagePreviewComponent key={imageId} url={imageDetails.url} fileId={imageDetails.fileId}
                                               name={imageDetails.name} task={imageDetails.task}
                                               taskId={imageDetails.taskId} callback={fetchTask}/>
                    ))}
                </Flex>
            ) : null}
            {loadingDwg && show ? <Spinner/> : show ? (
                <Flex wrap="wrap" justifyContent="center" gap="20px">
                    {Array.from(dwgData.entries()).map(([dwgId, dwgDetails]) => (
                        <DwgPreviewComponent key={dwgId} url={dwgDetails.url} fileId={dwgDetails.fileId} name={dwgDetails.name}
                                             task={dwgDetails.task} taskId={dwgDetails.taskId} callback={fetchTask}/>
                    ))}
                </Flex>
            ) : null}
            {loadingExcel && show ? <Spinner/> : show ? (
                <Flex wrap="wrap" justifyContent="center" gap="20px">
                    {Array.from(excelData.entries()).map(([excelId, excelDetails]) => (
                        <ExcelPreviewComponent key={excelId} url={excelDetails.url} fileId={excelDetails.fileId} name={excelDetails.name}
                                             task={excelDetails.task} taskId={excelDetails.taskId} callback={fetchTask}/>
                    ))}
                </Flex>
            ) : null}

            {isMember ? <Flex wrap="wrap" gap={2}>
                <TaskValidatorComponent status={props.status} callback={props.callback} idProject={props.idProject}
                                        task={props.task}/>
                <UploadButtonTasksComponent color={"black"} idTask={props.idTask} task={props.task}
                                            fetchData={fetchTask}/>
                <TaskModificatorComponent status={props.status} callback={props.callback} idProject={props.idProject}
                                          task={props.task}/>
                {(!show && !props.data) ? <ShowButtonComponent onClick={handleShowClick} status/> : null}
                {(show && !props.data)  ? <ShowButtonComponent onClick={handleShowClick} status={false}/> : null}
            </Flex> : null}
        </Box>
    )
}

export type {FileDetail};
export default TaskDetailsComponent;
