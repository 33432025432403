import React, {useEffect, useState} from "react";
import {
    Box,
    SimpleGrid,
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import InputComponent from "../../common/InputComponent";
import ButtonComponent from "../../common/ButtonComponent";

interface IProjectFormData {
    idClient: string;
    idReferent: string;
    begin: Date;
    end: Date;
    categories: string[];
    teams: string[];
    tags: string[];
    address: string;
    latitude: string;
    longitude: string;
    reference: string;
    notes: string;
    buildingType: string;
}

interface IProjectComponentProperties {
    formData: IProjectFormData;
    callback: (data: IProjectFormData) => void;
    clients: {value: string; label: string;}[];
    referents: {value: string; label: string;}[];
}

function NewProjectForm(props: IProjectComponentProperties) {
    const {t} = useTranslation();
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500 || (window.innerWidth > 667 && window.innerWidth < 950));
    const [formData, setFormData] = useState<IProjectFormData>(props.formData);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 500 || (window.innerWidth > 667 && window.innerWidth < 950));
        };
        setFormData(props.formData);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [props.formData]);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = event.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;

        setFormData({
            ...formData,
            [name]: new Date(value),
        });
    };
    const handleClient = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.callback(formData);
    };


    const formatDate = (date: number | Date) => {
        return new Intl.DateTimeFormat('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: '2-digit'
        }).format(date);
    };

    return (
        <Box width="100%" mt={5}>
            <form onSubmit={handleClient}>
                <InputComponent label={t("Select Client")} name={"idClient"} type={"select"}
                                selectOptions={props.clients}
                                onChange={handleChange} value={formData.idClient} isRequired/>
                <InputComponent label={t("Select Referent")} name={"idReferent"} type={"select"}
                                selectOptions={props.referents}
                                onChange={handleChange} value={formData.idReferent} isRequired/>
                <InputComponent label={t("Reference")} name={"reference"} type={"text"} value={formData.reference}
                                onChange={handleChange} isRequired/>
                <InputComponent label={t("buildingType")} name={"buildingType"} type={"text"} value={formData.buildingType}
                                onChange={handleChange} isRequired/>
                <SimpleGrid columns={isSmallScreen ? 1 : 2} spacingX={5}>
                    <InputComponent label={t("Begin")} name={"begin"} type={"date"}
                                    value={formData.begin.toISOString().split("T")[0]} onChange={handleDateChange}
                                    isRequired/>

                    <InputComponent label={t("End")} name={"end"} type={"date"}
                                    value={formData.end.toISOString().split("T")[0]} onChange={handleDateChange}
                                    isRequired/>
                </SimpleGrid>
                <InputComponent label={t("Address")} name={"address"} type={"text"} value={formData.address}
                                onChange={handleChange} isRequired/>
                <InputComponent label={t("Latitude")} name={"latitude"} type={"text"} value={formData.latitude}
                                onChange={handleChange} isRequired/>
                <InputComponent label={t("Longitude")} name={"longitude"} type={"text"} value={formData.longitude}
                                onChange={handleChange} isRequired/>
                <InputComponent label={t("Notes")} name={"notes"} type={"textArea"} value={formData.notes}
                                onChange={handleChangeTextArea} isRequired/>
                <Box display={"flex"} justifyContent={"flex-end"} mt={5}>
                    <ButtonComponent text={t("Enregistré")} callback={() => props.callback}/>
                </Box>
            </form>
        </Box>
    );
}

export type {IProjectFormData};
export default NewProjectForm;