import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {HamburgerIcon, SettingsIcon} from "@chakra-ui/icons";
import {useTranslation} from 'react-i18next';
import {useColorModeValue} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import {getAuthorizedHeader} from "../../common/auth";

function LinksNavComponent() {
  const {t} = useTranslation();
  const colorModeValue = useColorModeValue("black", "white");
  const [isMobile, setIsMobile] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const navigate = useNavigate();
  const bgColor = useColorModeValue("gray.300", "gray.600");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    axios.get('/api/is-admin', { headers: getAuthorizedHeader() })
        .then(response => {
          setIsAdmin(response.data.isAdmin);
        })
        .catch(error => {
          console.error("Error checking admin status:", error);
          setIsAdmin(false);
        });
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Flex
      alignItems="center"
      justifyContent={isMobile ? "flex-start" : "flex-start"}
      width="100%"
    >
      {isMobile ? (
        <Box>
          <Menu>
            <MenuButton width={"100%"} textAlign={isMobile ? "center" : "right"} m={0} p={0}>
              <IconButton
                onClick={isOpen ? onClose : onOpen}
                icon={<HamburgerIcon/>}
                size="md"
                aria-label="Settings"
                className="buttonStyle"
                boxShadow="md"
                _hover={{transform: "scale(1.1)", bg: bgColor}}
                m={0}
                p={0}
              />
            </MenuButton>
            <MenuList
              alignItems="center"
              bg={useColorModeValue("gray.200", "gray.700")}
              padding={2}
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                width: "160px",
              }}
            >
              <MenuItem bg={useColorModeValue("gray.200", "gray.700")} m={0}>
                <Button onClick={() => navigate('/tasks')} className="buttonStyle" width={"100%"}
                        boxShadow="md"
                        m={0} p={0}>
                  {t("tasks")}
                </Button>
              </MenuItem>
              <MenuItem bg={useColorModeValue("gray.200", "gray.700")} m={0}>
                <Button onClick={() => navigate('/calendar')} className="buttonStyle" width={"100%"}
                        boxShadow="md"
                        m={0} p={0}>
                  {t("calendar")}
                </Button>
              </MenuItem>
              <MenuItem bg={useColorModeValue("gray.200", "gray.700")} m={0}>
                <Button onClick={() => navigate('/projects')} className="buttonStyle" width={"100%"}
                        boxShadow="md"
                        m={0} p={0}>
                  {t("Projects")}
                </Button>
              </MenuItem>
              {isAdmin && (<MenuItem bg={useColorModeValue("gray.200", "gray.700")} m={0}>
                <Button onClick={() => navigate('/admin')} className="buttonStyle" width={"100%"}
                        boxShadow="md"
                        m={0} p={0}>
                  {t("admin")}
                </Button>
              </MenuItem>)}
              <MenuItem bg={useColorModeValue("gray.200", "gray.700")} m={0}>
                <Button onClick={() => navigate('/map')} className="buttonStyle" width={"100%"}
                        boxShadow="md"
                        m={0} p={0}>
                  {t("map")}
                </Button>
              </MenuItem>
              <MenuItem bg={useColorModeValue("gray.200", "gray.700")} m={0}>
                <Button onClick={() => navigate('/production')} className="buttonStyle" width={"100%"}
                        boxShadow="md"
                        m={0} p={0}>
                  {t("production")}
                </Button>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      ) : (
        <>
          <Button onClick={() => navigate('/tasks')}
                  className="buttonStyle"
                  boxShadow="md"
                  _hover={{transform: "scale(1.1)", bg: bgColor}}

                  ml={2}>
            {t("tasks")}
          </Button>
          <Button onClick={() => navigate('/calendar')}
                  className="buttonStyle"
                  boxShadow="md"
                  _hover={{transform: "scale(1.1)", bg: bgColor}}

                  ml={2}>
            {t("calendar")}
          </Button>
          <Button onClick={() => navigate('/projects')}
                  className="buttonStyle"
                  _hover={{transform: "scale(1.1)", bg: bgColor}}

                  boxShadow="md"
                  ml={2}>
            {t("Projects")}
          </Button>
          {isAdmin && (
          <Button onClick={() => navigate('/admin')}
                  className="buttonStyle"
                  boxShadow="md"
                  _hover={{transform: "scale(1.1)", bg: bgColor}}
                  ml={2}>
            {t("admin")}
          </Button>)}
          <Button onClick={() => navigate('/map')}
                  className="buttonStyle"
                  boxShadow="md"
                  _hover={{transform: "scale(1.1)", bg: bgColor}}
                  ml={2}>
            {t("map")}
          </Button>
          <Button onClick={() => navigate('/production')}
                  className="buttonStyle"
                  boxShadow="md"
                  _hover={{transform: "scale(1.1)", bg: bgColor}}
                  ml={2}>
            {t("production")}
          </Button>
        </>
      )}
    </Flex>
  );
}

export default LinksNavComponent;
