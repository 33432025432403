import React, { useEffect, useState } from "react";
import {
    Box,
    Flex,
    useBreakpointValue,
    useColorModeValue
} from "@chakra-ui/react";
import { ProgressBarComponent } from "./projectDetails/ProgressBarComponent";
import ClientComponent from "./projectDetails/ClientComponent";
import NotesComponent from "./projectDetails/NotesComponent";
import TeamsComponent from "./projectDetails/TeamsComponent";
import TasksComponent from "./projectDetails/TasksComponent";
import { checkIfUserIsLoggedIn } from "../LoginComponent";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getAuthorizedHeader } from "../../common/auth";
import DataComponent from "./projectDetails/DataComponent";
import MapComponent from "./projectDetails/MapComponent";
import TagsComponent from "./projectDetails/TagsComponent";
import ProjectDetailsMobileComponent from "./projectMobileDetails/ProjectDetailsMobileComponent";
import ReferentComponent from "./projectDetails/ReferentComponent";
import GanttTasks from "./projectDetails/GanttTasks/GanttTasks";

export interface IProject {
    _id: string;
    idClient: string;
    idReferent: string;
    idSignedQuote: string;
    idPreVisit: string;
    idCalculationNote: string;
    idPlan: string;
    idOrdersWithDeliveryDate: string;
    idEquipmentRental: string;
    idDelivery: string;
    idAccommodations: string;
    idPhotos: string;
    idInvoicing: string;
    idDate: string;
    categories: string[];
    teams: string[];
    tags: string[];
    address: string;
    latitude: string;
    longitude: string;
    notes: string;
    status: string;
    reference: string;
    buildingType: string;
}

interface IProjectDetails {
    idProject: string;
    callback: () => void;
    project: IProject;
}
function ProjectDetailsComponent(props: IProjectDetails) {
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const isLoggedIn = checkIfUserIsLoggedIn();
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const [project, setProject] = useState(props.project);

    async function fetchProgress() {
        try {
            const response = await axios.get(`/api/project/progress/` + props.idProject, {
                headers: getAuthorizedHeader(),
            });
            if (response.data && response.data.progress) {
                const progressPercentage = parseFloat(response.data.progress);
                setProgress(progressPercentage);
            }
            props.callback();
        } catch (error) {
            console.error('Error fetching project progress', error);
        }
    }

    useEffect(() => {
        setProject(props.project);
        if (isLoggedIn) {
            fetchProgress();
        } else {
            navigate('/login');
        }
    }, [isLoggedIn, navigate, props.idProject]);

    if (!project.teams || !project.tags) {
        return <Box>Error loading project details.</Box>;
    }

    if (isSmallScreen) {
        return (<ProjectDetailsMobileComponent project={project} idProject={props.idProject} />)
    } else {
        return (
            <Box
                padding={5}
                bg={useColorModeValue("gray.200", "gray.700")}
                height={"100%"}
                width={"100%"}
            >
                <ProgressBarComponent progress={progress} />
                <Flex flexDirection={"row"}>
                    <ClientComponent idClient={project.idClient} />
                    <ReferentComponent idReferent={project.idReferent}/>
                    <Flex flexDirection={'column'} width={"100%"}>
                        <NotesComponent notes={project.notes} />
                        <TeamsComponent teams={project.teams} />
                    </Flex>
                </Flex>
                <Flex flexDirection={'row'} marginTop={5}>
                    <TasksComponent
                        idProject={props.idProject}
                        idSignedQuote={project.idSignedQuote}
                        idPreVisit={project.idPreVisit}
                        idCalculationNote={project.idCalculationNote}
                        idPlan={project.idPlan}
                        idOrdersWithDeliveryDate={project.idOrdersWithDeliveryDate}
                        idEquipmentRental={project.idEquipmentRental}
                        idDelivery={project.idDelivery}
                        idAccommodations={project.idAccommodations}
                        idPhotos={project.idPhotos}
                        idInvoicing={project.idInvoicing}
                        callback={fetchProgress} />
                    <DataComponent
                        categories={project.categories}
                        address={project.address}
                        reference={project.reference}
                        idDate={project.idDate}
                        latitude={project.latitude}
                        longitude={project.longitude}
                        buildingType={project.buildingType}/>
                    <Flex flexDirection={'column'} width={"100%"}>
                        <MapComponent latitude={project.latitude}
                                      longitude={project.longitude} />
                        <TagsComponent tags={project.tags} />
                    </Flex>
                </Flex>
                <Flex>
                    <GanttTasks/>
                </Flex>
            </Box>
        )
    }
}

export default ProjectDetailsComponent;
