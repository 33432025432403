import React, {useEffect, useState} from "react";
import {
    Box,
    Divider,
    Heading,
    IconButton,
    Text,
    useBreakpointValue,
    useColorModeValue,
    useToast
} from "@chakra-ui/react";
import axios from "axios";
import {getAuthorizedHeader} from "../../../common/auth";
import {CopyIcon} from "@chakra-ui/icons";
import CopyButtonComponent from "../../common/CopyButtonComponent";
import DataDetails from "./DataDetailsComponent";
import {useTranslation} from "react-i18next";


interface DataProps {
    categories: string[];
    address: string;
    buildingType: string;
    latitude: string;
    longitude: string;
    reference: string;
    idDate: string;
}

interface DateContent {
    begin: string;
    end: string;
}
function DataComponent(props: DataProps) {
    const {t} = useTranslation();
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const [date, setdate] = useState({} as DateContent);
    const [isLoading, setIsLoading] = useState(true);

    const fetchDate = async (idDate: string): Promise<void> => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/date/' + idDate, {
                headers: getAuthorizedHeader(),
            });
            setdate(response.data);
        } catch (error) {
            console.error('Error fetching Date:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDate(props.idDate);
    }, [props.idDate]);

    if (isLoading) {
        return <div>{t("Loading")}</div>;
    }

    if (!date) {
        return <div>{t("NotData")}</div>;
    }

    return (
        <Box padding={1}
             bg={useColorModeValue('white', 'gray.600')}
             border-radius={4}
             rounded="md"
             boxShadow="md"
             marginX={isSmallScreen ? 0 : 5}
             width={isSmallScreen ? "100%" : 'calc(50% - 10px)'}
            >
            <Heading alignItems="flex-start" fontSize='2xl' color="gray.300">{t("data")}</Heading>
            <Divider  width="100%"/>
            <DataDetails header={t("BuildingType")} detail={props.buildingType}/>
            <DataDetails header={t("Categories")} detail={props.categories[0]}/>
            <DataDetails header={t("Address")} detail={props.address}/>
            <DataDetails header={t("GPS")} detail={`${props.latitude} ${props.longitude}`}/>
            <DataDetails header={t("date")} detail={`${date.begin} / ${date.end}`}/>
            <DataDetails header={t("Reference")} detail={props.reference}/>
        </Box>
    )
}

export default DataComponent;