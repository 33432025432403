import React, { useEffect, useState } from "react";
import {Box, Flex, Spinner, Text, useBreakpointValue, useColorModeValue, useToast} from "@chakra-ui/react";
import NavBar from "../components/navigation/NavBarComponent";
import SelectComponent from "../components/common/SelectComponent";
import { faCalendar, faCalendarDay, faCalendarWeek } from "@fortawesome/free-solid-svg-icons";
import AppointmentComponent, { IAppointment } from "../components/Agenda/AppointmentComponent";
import CalendarComponent from "../components/Agenda/CalendarComponent";
import SmallScreenAgenda from "../components/Agenda/SmallScreenAgenda";
import WeekCalendar from "../components/Agenda/WeekCalendar";
import axios from "axios";
import { getAuthorizedHeader } from "../common/auth";
import { fetchProjects } from "./ProjectScreen";
import PageLoadingSpinner from "../components/common/PageLoadingSpinner";

export const parseEventDate = (date: string, time: string) => {
    const [day, month, year] = date.split('/');
    const [hours, minutes] = time.split(':');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes));
};

export interface IEvent {
    _id: string;
    start: Date;
    end: Date;
    title: string;
    type: string;
    note: string;
    participants: string[];
}

function CalendarScreen() {
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [appointments, setAppointments] = useState<IAppointment[]>([]);
    const [saveAppointments, setSaveAppointments] = useState<IAppointment[]>([]);
    const [selectedView, setSelectedView] = useState<string>('month');

    useEffect(() => {
        if (isSmallScreen) {
            setSelectedView('day');
        } else {
            setSelectedView('month');
        }
    }, [isSmallScreen]);

    const fetchAppointments = async () => {
        try {
            const response = await axios.get('/api/rdv', { headers: getAuthorizedHeader() });
            setSaveAppointments(response.data);
            setAppointments(response.data);
            setLoading(false);
        } catch (err) {
            console.error(err);
            setError("Failed to fetch appointments");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAppointments();
    }, []);

    if (loading) {
        return <Box padding={5} bg={useColorModeValue("gray.200", "gray.700")}>
            <PageLoadingSpinner />
        </Box>;
    }

    if (error) {
        return <Text color="red.500">{error}</Text>;
    }

    const events = [...saveAppointments];

    function handleTagChange(newTag: string) {
        setSelectedView(newTag);
    }

    return (
        <Box width={"100%"}>
            <NavBar />
            <Box maxHeight={window.innerHeight - 164} minHeight={window.innerHeight - 164} m={0}>
                <Flex marginTop={3}>
                    <SelectComponent
                        selectedTag={selectedView}
                        callback={handleTagChange}
                        option1={"week"}
                        option2={"month"}
                        option3={"day"}
                        option1Icon={faCalendarWeek}
                        option2Icon={faCalendar}
                        option3Icon={faCalendarDay}
                    />
                </Flex>
                <Box display={'flex'} flexDirection={isSmallScreen ? "column" : "row"} height={"75%"}>
                    {selectedView === "week" && <WeekCalendar events={events} />}
                    {selectedView === "month" && <CalendarComponent appointments={saveAppointments} />}
                    {selectedView === "day" && <SmallScreenAgenda events={events} />}
                    <AppointmentComponent events={saveAppointments} fetchAppointments={fetchAppointments} />
                </Box>
            </Box>
        </Box>
    );
}

export default CalendarScreen;
